import type { EmeraldIconInputs } from '@emerald-react/icon';
import { EmeraldIcon } from '@emerald-react/icon';
import type { EmeraldIconTypes } from '@emerald/nxcore';
import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';
import { forwardRef } from 'react';
import type { AccountTreeRoundIconProps } from './AccountTreeRoundIcon';
import { AccountTreeRoundIcon } from './AccountTreeRoundIcon';

const containerSx = { display: 'inline-flex' } satisfies BoxProps['sx'];

type Icon = EmeraldIconTypes | 'account_tree_round';

export type IconProps<TIcon extends Icon> = TIcon extends 'account_tree_round'
  ? { icon: 'account_tree_round' } & AccountTreeRoundIconProps
  : { icon: EmeraldIconTypes } & Omit<EmeraldIconInputs, 'icon'>;

export const Icon = forwardRef<HTMLElement, IconProps<Icon>>(
  function Icon(props, ref) {
    // Temporarily use AccountTreeRoundIcon until all icons are implemented
    if (props.icon === 'account_tree_round') {
      return (
        <Box
          sx={containerSx}
          ref={ref}
        >
          <AccountTreeRoundIcon {...props} />
        </Box>
      );
    }

    return (
      <Box
        sx={containerSx}
        ref={ref}
      >
        <EmeraldIcon {...props} />
      </Box>
    );
  }
);
