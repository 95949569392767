import { SidebarContext } from './SidebarContext';
import type { PropsWithChildren } from 'react';
import { useState } from 'react';

export type SidebarProviderProps = PropsWithChildren;

export function SidebarProvider(props: SidebarProviderProps) {
  const { children } = props;
  const localIsOpen = localStorage.getItem('navdrawerstateopen') === 'true';
  const [isOpen, setIsOpen] = useState(localIsOpen);

  return (
    <SidebarContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </SidebarContext.Provider>
  );
}
