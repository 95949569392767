import { styled, TablePagination, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import TablePaginationActions from './PaginationActions';

function EnhancedPagination({ pagination, totalRows, rowsPerPage, page }) {
  const theme = useTheme();
  const customPaginationCSS = `
.MuiToolbar-root .MuiStack-root .MuiStack-root .MuiIconButton-root {
  color: ${theme.palette.text.primary};
  height: 32px;
  margin-top: 15px;
}
.MuiToolbar-root .MuiStack-root .MuiIconButton-sizeMedium {
  height: 40px;
  margin-top: 10px;
}
.MuiTablePagination-displayedRows,
.MuiTablePagination-spacer {
  display: none;
}
.MuiTablePagination-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${theme.palette.divider};

}
.MuiInputBase-root.MuiInputBase-colorPrimary {
  position: absolute;
  left: 6.4rem;
  font-weight: 500;
}
.MuiTablePagination-selectLabel {
  font-size: 12px;
  color: ${theme.palette.text.secondary};
  text-transform: capitalize;
  font-family: "Calibre-R";
  // background-color: #e0e8
}
.MuiSelect-select.MuiTablePagination-select.MuiSelect-standard.MuiInputBase-input {
  background-color: ${theme.palette.primary.contrastText};
  border-radius: 4px;
}
.MuiInputBase-root.MuiInputBase-colorPrimary {
  margin-left: 9rem;
  font-family: "Calibre-R";
}
`;
  const getPaginatedString = () => {
    const currentPageStart = Math.max(page + 1, page * rowsPerPage + 1);
    let currentPageEnd = page * rowsPerPage + rowsPerPage;
    if (currentPageEnd > totalRows) currentPageEnd = totalRows || rows?.length;

    if (
      typeof currentPageStart === 'number' ||
      typeof currentPageEnd === 'number' ||
      typeof totalRows === 'number'
    ) {
      return `${currentPageStart} - ${currentPageEnd} of ${totalRows || rows?.length}`;
    }
    return '';
  };
  const CustomizedPagination = styled(TablePagination)(customPaginationCSS);

  return (
    <CustomizedPagination
      rowsPerPageOptions={pagination?.rowsPerPageOptions || [5, 10, 25, 50]}
      component='div'
      count={totalRows || 0}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={pagination?.pageHandler}
      labelRowsPerPage={
        <>
          <Typography
            variant='body2'
            textTransform={'none'}
          >
            {getPaginatedString()}
          </Typography>
          <Typography variant='body2'>
            <span style={{ marginLeft: 7.5, marginRight: 7.5 }}>&#8226;</span>
            <span>Rows per page</span>
          </Typography>
        </>
      }
      onRowsPerPageChange={pagination?.rowsPerPageHandler}
      ActionsComponent={TablePaginationActions}
    />
  );
}

EnhancedPagination.propTypes = {
  pagination: PropTypes.shape({
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
    pageHandler: PropTypes.func.isRequired,
    rowsPerPageHandler: PropTypes.func.isRequired,
  }).isRequired,
  totalRows: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
};

export default EnhancedPagination;
