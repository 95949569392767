import { useQuery } from '@tanstack/react-query';
import Api from '@/utils/Api.js';

interface ApplicationPermission {
  user_id: string;
  project_id: number;
  application_id: number;
  project_name: string;
  application_name: string;
  icon_url: string;
  nav_path: string;
  category: string;
  dis: string;
}

export interface GetUserPermissionsResponse {
  isAdmin: boolean;
  userId: string;
  permissions: {
    apps: ApplicationPermission[];
    directAccess: ApplicationPermission[];
  };
}

export function useUserPermissions<TSelector = GetUserPermissionsResponse>(
  select?: (data: GetUserPermissionsResponse) => TSelector
) {
  return useQuery<GetUserPermissionsResponse, Error, TSelector>({
    queryKey: ['getUserPermissions'],
    queryFn: async () => {
      const response = await Api.get<GetUserPermissionsResponse>(
        '/getUserPermissions'
      );
      return response.data;
    },
    select,
  });
}
