import { useClientBuildings } from '@smartfm/container/hooks/useClientBuildings';
import { useDebounce, useLocalStorage } from '@uidotdev/usehooks';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import type { BuildingSelectorProps } from './BuildingSelector';
import { BuildingSelector } from './BuildingSelector';
import { Button } from '@smartfm/ui/components/Button';
import './styles.scss';

const storagePrefixKey = 'buildingSelector';
const isFavoriteFilterActiveKey = [
  storagePrefixKey,
  'isFavoriteFilterActive',
].join('.');
const favoriteItemsKey = [storagePrefixKey, 'favoriteBuildings'].join('.');
const searchDebounceMs = 1000;

type TransformedResponse = Pick<
  BuildingSelectorProps,
  'itemsMap' | 'itemsOrder' | 'totalCount'
>;

interface ConnectedBuildingSelectorProps {
  position: NonNullable<BuildingSelectorProps['position']>;
  clientId: string;
}

export function ConnectedBuildingSelector(
  props: ConnectedBuildingSelectorProps
) {
  const { clientId, ...otherProps } = props;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const recordId = searchParams.get('recordId');

  // If the recordId is not the same as the clientId, use it as the default value
  // Otherwise, use the special value for all buildings
  const value =
    recordId && recordId !== clientId
      ? recordId
      : BuildingSelector.__ALL_BUILDINGS_VALUE__;

  const [searchField, setSearchField] = useState<string>();
  const debouncedSearchField = useDebounce(searchField, searchDebounceMs);
  const { data, isFetching, isLoading } =
    useClientBuildings<TransformedResponse>({
      clientId,
      searchField: debouncedSearchField,
      // We want to transform response before caching it
      // to avoid unnecessary transformations on each render
      transform(response) {
        const { data, totalCount } = response.data;
        return {
          itemsMap: data.reduce<TransformedResponse['itemsMap']>(
            (acc, building) => {
              acc[building.nexus_id] = {
                value: building.nexus_id,
                label: building.nav_name,
                secondaryText: building.address ?? undefined,
              };
              return acc;
            },
            {}
          ),
          itemsOrder: data.map(building => building.nexus_id),
          totalCount
        };
      },
    });

  // Keep the current label in case
  // the new search results don't contain the current value
  const defaultLabelRef = useRef('');

  useEffect(() => {
    // TODO: improve useClientBuildings typing to remove extra check here
    if (!data || !('itemsMap' in data)) return;
    const { itemsMap } = data;
    const item = itemsMap[value];

    // If the item is not found, do nothing
    if (!item) return;
    defaultLabelRef.current = item.label;
  }, [data, value]);

  const [isFavoriteFilterActive, setIsFavoriteFilterActive] = useLocalStorage(
    isFavoriteFilterActiveKey,
    false
  );

  const [favoriteItems, setFavoriteItems] = useLocalStorage<
    Record<string, string[]>
  >(favoriteItemsKey, {});
  const favoriteItemsForClient = favoriteItems[clientId] || [];

  function handleFavoriteChange(value: string) {
    setFavoriteItems(existingFavoriteItems => {
      const favoritesForClient = existingFavoriteItems[clientId] || [];
      const updatedFavoritesForClient = favoritesForClient.includes(value)
        ? favoritesForClient.filter(item => item !== value)
        : [...favoritesForClient, value];

      if (updatedFavoritesForClient.length === 0) {
        setIsFavoriteFilterActive(false);
        const { [clientId]: _, ...restClients } = existingFavoriteItems;
        return restClients;
      }
      return {
        ...existingFavoriteItems,
        [clientId]: updatedFavoritesForClient,
      };
    });
  }

  function handleValueChange(value: string) {
    const recordId =
      value === BuildingSelector.__ALL_BUILDINGS_VALUE__ ? clientId : value;
    const queryParams = new URLSearchParams({
      projectId: clientId,
      recordId,
    });

    // Update the URL with the new projectId and recordId
    navigate(`?${queryParams.toString()}`);
  }

  // Reset the search field when the selector is closed
  function handleClose() {
    setSearchField('');
  }

  // Handle favorites dead-end state when switching between clients
  useEffect(() => {
    if (!searchField && favoriteItemsForClient.length === 0) {
      setIsFavoriteFilterActive(false);
    }
  }, [favoriteItemsForClient, setIsFavoriteFilterActive, searchField]);

  // TODO: Consider using isLoading prop inside the BuildingSelector component
  if (isLoading) {
    return (
      <Button
        icon='place'
        rightIcon='arrow_drop_down'
        size='Small'
        type='TextButton'
        className='building-selector__trigger'
        label='Fetching ...'
      />
    );
  }

  // TODO: improve useClientBuildings typing to remove extra check here
  if (!data || !('itemsMap' in data)) return null;
  const { itemsMap, itemsOrder, totalCount } = data;

  return (
    <BuildingSelector
      // Value handling
      value={value}
      itemsMap={itemsMap}
      itemsOrder={itemsOrder}
      totalCount={totalCount}
      onValueChange={handleValueChange}
      defaultLabel={defaultLabelRef.current}
      // Search handling
      searchValue={searchField}
      onSearchChange={setSearchField}
      onClose={handleClose}
      // Favorite items handling
      favoriteItems={favoriteItemsForClient}
      onFavoriteChange={handleFavoriteChange}
      isFavoriteFilterActive={isFavoriteFilterActive}
      onFavoriteFilterChange={setIsFavoriteFilterActive}
      // Metadata
      isLoading={isFetching}
      {...otherProps}
    />
  );
}
