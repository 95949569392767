import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import type { ReactNode } from 'react';
import { useDashboardContext } from '@/context/dashboard';
import { ErrorCardContent } from '../../cards/ErrorCardContent.jsx';
import { useNavigationCtx } from '../../NavigationProvider.jsx';
import type { GetAssetTypesUnresolvedIssuesWidgetDataItem } from './AssetTypesUnresolvedIssuesWidget.service.ts';
import { getAssetTypesUnresolvedIssuesWidgetData } from './AssetTypesUnresolvedIssuesWidget.service.ts';
import '../IssuesWidget/IssuesWidget.scss';
import type { BarChartEntry } from '../../charts/BarChart';
import BarChart from '../../charts/BarChart';

export const AssetTypesUnresolvedIssuesWidget = () => {
  return (
    <Box
      className='widget widget-n'
      sx={{ padding: 2 }}
    >
      <WidgetContent />
    </Box>
  );
};

const WidgetContent = () => {
  const navigationCtx = useNavigationCtx();
  const { refreshDuration, refreshEvent } = useDashboardContext();

  const projectId = navigationCtx?.state.selectedOption?.project_nexus_id;
  const buildingId = navigationCtx?.state.selectedOption?.nexusId;

  const widgetQuery = useQuery({
    queryKey: [
      'getAssetTypesUnresolvedIssuesWidgetData',
      projectId,
      buildingId,
      refreshEvent,
    ],
    enabled: Boolean(buildingId) && Boolean(projectId),
    refetchInterval: 1000 * refreshDuration * 60,
    queryFn: () =>
      getAssetTypesUnresolvedIssuesWidgetData({
        projectId: projectId!,
        buildingId: buildingId!,
      }).then(transformResponseToBarChartEntries),
  });

  if (widgetQuery.isLoading) {
    return (
      <div
        className='top'
        style={{ height: '100%' }}
      >
        <Skeleton
          variant='rectangular'
          height='100%'
        />
      </div>
    );
  }

  if (widgetQuery.error) {
    return <WidgetErrorReload onReload={() => widgetQuery.refetch()} />;
  }

  if (!widgetQuery.data || !widgetQuery.data.length) {
    return <NoDataAvailable />;
  }

  const widgetData = widgetQuery.data;

  return (
    <WidgetCard
      top={<WidgetCardTitle />}
      bottom={
        <Box sx={{ width: '100%', height: '100%' }}>
          <Stack sx={{ flexGrow: 1, width: '100%', height: '100%' }}>
            <Stack
              direction='row'
              justifyContent='space-between'
              sx={{ paddingX: 1 }}
            >
              <Box sx={{ color: 'text.primary', opacity: 0.65 }}>
                Asset Types
              </Box>
              <Box sx={{ color: 'text.primary', opacity: 0.65 }}>Issues</Box>
            </Stack>

            <Box sx={{ flexGrow: 1, paddingX: 1, overflow: 'auto' }}>
              <BarChart data={widgetData} />
            </Box>
          </Stack>
        </Box>
      }
    />
  );
};

interface WidgetCardProps {
  top: ReactNode;
  bottom: ReactNode;
}

const WidgetCard = ({ top, bottom }: WidgetCardProps) => (
  <>
    <div className='top'>{top}</div>
    <Box
      className='bottom'
      sx={{ overflow: 'hidden' }}
    >
      {bottom}
    </Box>
  </>
);

const WidgetCardTitle = () => {
  return (
    <Box sx={{ paddingX: 1 }}>
      <Typography
        sx={{
          fontSize: '20px',
          fontWeight: '500',
          lineHeight: '24px',
          textAlign: 'left',
          textUnderlinePosition: 'from-font',
          textDecorationSkipInk: 'none',
          color: '#1A1A1A',
        }}
      >
        Asset Types Unresolved Issues
      </Typography>
    </Box>
  );
};

interface WidgetErrorReloadProps {
  onReload: () => void;
}

const WidgetErrorReload = ({ onReload }: WidgetErrorReloadProps) => (
  <div
    className='top'
    style={{ height: '100%' }}
  >
    <div className='top'>
      <WidgetCardTitle />
    </div>
    <ErrorCardContent onReload={onReload} />
  </div>
);

const NoDataAvailable = () => (
  <div
    className='top'
    style={{ height: '100%' }}
  >
    <div className='top'>
      <WidgetCardTitle />
    </div>
    <Stack
      justifyContent='center'
      alignItems='center'
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <Typography color='rgba(26, 26, 26, 0.65)'>
        No data is available
      </Typography>
    </Stack>
  </div>
);

export function transformResponseToBarChartEntries(
  data: GetAssetTypesUnresolvedIssuesWidgetDataItem[]
): BarChartEntry[] {
  return data.map(item => ({
    name: item.equip_type,
    value: item.issue_count,
    color: '#80BBAD',
  }));
}
