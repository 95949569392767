import isEmpty from 'lodash.isempty';
import { useProjects } from './useProjects';

interface App {
  application_id: number;
}

interface Client {
  id: string;
  name: string;
  apps: App[];
}

export interface Params {
  searchField?: string;
}

interface ItemsMap {
  [key: string]: {
    value: string;
    label: string;
  };
}

interface Result {
  itemsMap: ItemsMap;
  itemsOrder: string[];
}

const emptyResult: Result = { itemsMap: {}, itemsOrder: [] };

export function useClients(params: Params) {
  const { searchField = '' } = params;

  const { data, isPending, error, refetch } = useProjects(response => {
    if (!response?.data) {
      return emptyResult;
    }

    const { data: clients } = response;
    const { itemsMap, itemsOrder } = clients.reduce(
      (acc: Result, client: Client) => {
        const { id, name } = client;
        acc.itemsMap[client.id] = { label: name, value: id };
        acc.itemsOrder.push(client.id);
        return acc;
      },
      { itemsMap: {}, itemsOrder: [] }
    );

    const filteredItemsOrder = itemsOrder.filter(itemId => {
      const item = itemsMap[itemId];
      return item.label.toLowerCase().includes(searchField.toLowerCase());
    });

    return { itemsMap, itemsOrder: filteredItemsOrder };
  });

  // /navigation/projects should return totalCount
  // and we should use it to determine if there are any items instead of checking itemsMap
  const customError =
    error ||
    (!isPending && (!data || isEmpty(data.itemsMap))
      ? new Error('Clients list is empty')
      : null);

  return {
    error: customError,
    isPending,
    refetch,
    ...(data ?? emptyResult),
  };
}
