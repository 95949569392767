import { Helmet } from 'react-helmet';

const titleCase = word => word.charAt(0).toUpperCase() + word.slice(1);
const applicationsName = [
  {
    name: 'CBM',
    id: 'cbm',
  },
];

const getTitle = () => {
  const path = window.location.pathname;
  if (path && path != '/') {
    const subtitle = path.split('/').slice(-1)[0];
    const foundLabel = applicationsName.find(app => app.id === subtitle);

    return `SmartFM - ${foundLabel?.name ?? titleCase(subtitle)}`;
  }

  return `CBRE SmartFM`;
};

const HTMLHead = () => {
  return (
    <Helmet>
      {/* HTML LINKS HERE */}
      <link
        rel='icon'
        href='./favicon.ico'
      />
      <link
        rel='manifest'
        href='./manifest.json'
      />

      {/* Header Metadata Here */}
      <meta charSet='utf-8' />
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1'
      />
      <meta
        name='description'
        content='A CBRE Facilities Management Platform'
      />

      {/* Page Title Here */}
      <title>{`${getTitle()}`}</title>
    </Helmet>
  );
};

export default HTMLHead;
