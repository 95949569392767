export const getWidgetFilterInterval = interval => {
  return interval === 'thisMonth' || interval === 'lastMonth'
    ? 'Monthly'
    : interval === 'thisWeek' || interval === 'lastWeek'
      ? 'Weekly'
      : interval == 'last30Days'
        ? 'Last 30 days'
        : 'All';
};

export const getWidgetMapping = widget => {
  switch (widget) {
    case '2':
      return 'issues';
    case '3':
      return 'issues';
    case '9':
      return 'issues';
    case '19':
      return 'issues';
    case '11':
      return 'weather';
    case '8':
      return 'health';
    case '5':
      return 'legionellaCompliance';
    // Add more cases for other categories if needed
    default:
      return null;
  }
};

export function getAppLinkForClient(pathName, clientId) {
  // Has only all clients page
  if (pathName.startsWith('/ui/moe')) {
    return pathName;
  } else if (pathName.startsWith('/ui/issue')) {
    const queryParams = new URLSearchParams({
      records: clientId,
      recordId: 'All',
      recordType: 'project',
      project: clientId,
    });
    return `${pathName}?${queryParams.toString()}`;
  }
  // /
  // /ui/cbm
  // /ui/pipe-monitoring
  // /ui/smart-cleaning
  else {
    const queryParams = new URLSearchParams({
      recordId: clientId,
    });
    return `${pathName}?${queryParams.toString()}`;
  }
}

export const isNumeric = value => /^[-+]?[0-9]+(\.[0-9]*)?$/.test(value);

export const numberFormat = args =>
  new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
    style: args?.style || 'decimal',
    currency: args?.currency || undefined,
  });

export const formatNumber = (numb, options) => {
  return typeof numb === 'string' || isNumeric(numb)
    ? numberFormat(options).format(numb)
    : numb;
};

/** Normalize a string value by removing special characters and converting to lowercase
 * @param {string} value
 * @returns {string}
 */
export function normalizeValue(value) {
  if (!value) return '';

  return (
    value
      .toLowerCase()
      // Replace underscores and multiple spaces with single space
      .replace(/[_\s]+/g, ' ')
      // Remove any special characters and keep numbers, letters, and spaces
      .replace(/[^\w\s]/g, '')
      .trim()
  );
}

export function getUniqueObjects(arr, key) {
  const uniqueMap = new Map();
  
  arr.forEach(obj => {
    if (!uniqueMap.has(obj[key])) {
      uniqueMap.set(obj[key], obj);
    }
  });
  
  return [...uniqueMap.values()];
}