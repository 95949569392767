import { Circle, Info } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import useStyles from './useStyles';
import { MediumSeverityIcon } from '../icons/Icons';

function IssueCell({ high, medium, low }) {
  const classes = useStyles();
  const priorityToColor = priority => {
    if (priority <= 30) return '#8585E0';
    if (priority <= 60) return '#FF9742';
    return '#FF543E';
  };
  return (
    <Stack
      direction='row'
      spacing={1}
      className={classes.eventCell}
    >
      <Stack
        direction='row'
        spacing={1}
        className={classes.eventCell}
      >
        {+high > 0 || typeof high === 'string' ? (
          <>
            <Circle sx={{ fontSize: '18px', fill: '#FF543E' }} />
            <Typography
              style={{ color: '#1A1A1AA6' }}
              variant='body2'
            >
              {high}
            </Typography>
          </>
        ) : (
          <>
            <Circle sx={{ fontSize: '0px', fill: 'transparent' }} />
            <Typography
              style={{ color: 'transparent' }}
              variant='body2'
            >
              {'12'}
            </Typography>
          </>
        )}
      </Stack>
      <Stack
        direction='row'
        spacing={1}
        className={classes.eventCell}
      >
        {+medium > 0 || typeof high === 'string' ? (
          <>
            <MediumSeverityIcon sx={{ fill: '#FF9742', fontSize: 18 }} />

            <Typography
              style={{ color: '#1A1A1AA6' }}
              variant='body2'
            >
              {medium}
            </Typography>
          </>
        ) : (
          <>
            <Circle sx={{ fontSize: '18px', fill: 'transparent' }} />
            <Typography
              style={{ color: '#1A1A1AA6' }}
              variant='body2'
            >
              {''}
            </Typography>
          </>
        )}
      </Stack>
      <Stack
        direction='row'
        spacing={1}
        className={classes.eventCell}
      >
        {+low > 0 || typeof high === 'string' ? (
          <>
            <Info sx={{ fontSize: '18px', fill: '#8585E0' }} />
            <Typography
              style={{ color: '#1A1A1AA6' }}
              variant='body2'
            >
              {low}
            </Typography>
          </>
        ) : (
          <>
            <Circle sx={{ fontSize: '18px', fill: 'transparent' }} />
            <Typography
              style={{ color: '#1A1A1AA6' }}
              variant='body2'
            >
              {''}
            </Typography>
          </>
        )}
      </Stack>
      {/* <Warning sx={{ fontSize: "18px", fill: "#FF9742" }} />
        <Typography style={{ color: "#1A1A1AA6" }}>{medium}</Typography>
        <Info sx={{ fontSize: "18px", fill: "#8585E0" }} />
        <Typography style={{ color: "#1A1A1AA6" }}>{low}</Typography> */}
    </Stack>
  );
}
IssueCell.propTypes = {
  // can be either a number or a string
  high: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  medium: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  low: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default IssueCell;
