import { isProd } from '#utils/env';
import { getUserData } from '@/utils/okta';
import { datadogRum } from '@datadog/browser-rum';

interface UserData {
  email: string;
  firstname: string;
  lastname: string;
}

function isUserData(data: unknown): data is UserData {
  return (
    data !== null &&
    typeof data === 'object' &&
    'email' in data &&
    'firstname' in data &&
    'lastname' in data
  );
}

export async function initDatadogRum(id: string) {
  const [applicationId, clientToken] = isProd()
    ? [
        import.meta.env.VITE_DATADOG_APPLICATION_ID_PROD,
        import.meta.env.VITE_DATADOG_CLIENT_TOKEN_PROD,
      ]
    : [
        import.meta.env.VITE_DATADOG_APPLICATION_ID,
        import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
      ];

  if (!(applicationId && clientToken)) return;

  datadogRum.init({
    applicationId,
    clientToken,
    site: 'datadoghq.com',
    service: 'smart_fm',
    env: window.__NX_ENV__,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 60,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  const userData = getUserData();
  if (!isUserData(userData)) {
    console.error('User data for RUM is missing.');
    return;
  }

  const { email, firstname, lastname } = userData;
  const userName = [lastname, firstname].filter(Boolean).join(' ') || undefined;

  datadogRum.setUser({
    id,
    name: userName,
    email: email || undefined,
  });
}
