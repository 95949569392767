import { ERROR_MESSAGE } from '@smartfm/react-components';
import { createContext, useContext, useState } from 'react';

const ctx = createContext(
  /**
   * @type {null | {
   *    state: {
   *        selectedOption?: import('@smartfm/react-components').Option,
   *        allOptions: import('@smartfm/react-components').Option[]
   *    };
   *    setState: React.Dispatch<React.SetStateAction<{
   *        selectedOption?: import('@smartfm/react-components').Option,
   *        allOptions: import('@smartfm/react-components').Option[]
   *    }>>
   * }}
   */
  (null)
);

export const useNavigationCtx = () => {
  const _ctx = useContext(ctx);
  if (ctx === null)
    throw new Error(ERROR_MESSAGE.CALLED_OUTSIDE_CONTEXT_PROVIDER);
  return _ctx;
};

export function NavigationProvider({ children }) {
  const [state, setState] = useState(
    /**
     * @type {{
     *        selectedOption?: import('@smartfm/react-components').Option,
     *        allOptions: import('@smartfm/react-components').Option[]
     *    }}
     */
    ({
      allOptions: [],
    })
  );
  return (
    <ctx.Provider
      value={{
        state,
        setState,
      }}
    >
      {children}
    </ctx.Provider>
  );
}
