import { isProd } from "#utils/env";
import { api } from "@/utils/Api";
import * as z from "zod";

const isUsingMockData = !isProd() && false;

export interface GetAssetTypesUnresolvedIssuesWidgetDataItem {
  equip_type: string;
  issue_count: number;
}

type GetAssetTypesUnresolvedIssuesWidgetDataResponse =
  GetAssetTypesUnresolvedIssuesWidgetDataItem[];

export const mockData: GetAssetTypesUnresolvedIssuesWidgetDataResponse = [
  { equip_type: "Air Handling Unit", issue_count: 256 },
  { equip_type: "RTU", issue_count: 105 },
  { equip_type: "FCU", issue_count: 55 },
  { equip_type: "Chiller", issue_count: 20 },
];

interface GetAssetTypesUnresolvedIssuesWidgetDataProps {
  projectId: string;
  buildingId: string;
}

export const getAssetTypesUnresolvedIssuesWidgetData = async ({
  projectId,
  buildingId,
}: GetAssetTypesUnresolvedIssuesWidgetDataProps) => {
  const rawData = isUsingMockData
    ? mockData
    : (
        await api.get<GetAssetTypesUnresolvedIssuesWidgetDataResponse>(
          "issues/getIssueCountByEquipType",
          {
            params: {
              projectId,
              siteId: buildingId,
            },
          }
        )
      ).data;

  return validate(rawData);
};

function validate(
  responseBody: GetAssetTypesUnresolvedIssuesWidgetDataResponse
) {
  const schema = z.array(
    z.object({
      equip_type: z.string(),
      issue_count: z.number().min(1),
    })
  );

  const parseResult = schema.safeParse(responseBody);
  if (parseResult.error) {
    const error = parseResult.error.errors.at(0) || {
      message: parseResult.error.message,
      path: [],
    };

    throw new Error(
      `Validation error: ${error.message}\nPath: ${JSON.stringify(error.path)}`
    );
  }

  return parseResult.data;
}
