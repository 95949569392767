import { Toolbar, Typography } from '@mui/material';
import PropTypes from 'prop-types';

function EnhancedTableToolbar({ children, tableName, sx }) {
  return (
    <Toolbar sx={{ ...sx }}>
      <Typography
        sx={{ flex: '1 1 100%', lineHeight: '24px' }}
        variant='h6'
        id='tableTitle'
        component='div'
      >
        {tableName}
      </Typography>
      {children}
    </Toolbar>
  );
}
EnhancedTableToolbar.propTypes = {
  children: PropTypes.node,
  tableName: PropTypes.string.isRequired,
  sx: PropTypes.object,
};
export default EnhancedTableToolbar;
