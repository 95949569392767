import { handleError } from './utils';
import { createTheme, ThemeProvider } from '@mui/material';
import { ErrorFallback, theme } from '@smartfm/react-components';
import { ComponentType, ReactNode, useCallback } from 'react';
import { FallbackProps, ErrorBoundary } from 'react-error-boundary';

export function ErrorBoundaryWithTheme({
  children,
  isTopLevelBoundary = false,
}: {
  children: ReactNode;
  isTopLevelBoundary: boolean;
}) {
  const _FallbackComponent: ComponentType<FallbackProps> = useCallback(() => {
    return <ErrorFallback isGoToHomeButtonHidden={isTopLevelBoundary} />;
  }, [isTopLevelBoundary]);
  return (
    <ThemeProvider theme={createTheme(theme)}>
      <ErrorBoundary
        FallbackComponent={_FallbackComponent}
        onError={handleError}
      >
        {children}
      </ErrorBoundary>
    </ThemeProvider>
  );
}
