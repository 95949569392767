import { Drawer } from '@mui/material';
import React, { createContext } from 'react';

const drawerContext = createContext(
  /**
   * @type {null | {
   *  setDrawerComponent: React.Dispatch<React.SetStateAction<React.ReactNode>>
   * }}
   */
  (null)
);
export const useDrawerContext = () => {
  const context = React.useContext(drawerContext);
  if (!context) {
    throw new Error('useDrawerContext must be used within a DrawerProvider');
  }
  return context;
};

/**
 * @param {{ children: React.ReactNode }} param0
 */
export function DrawerProvider({ children }) {
  //   const drawerComponent = useDrawerStore((s) => s.drawerComponent);
  //   const open = useDrawerStore((s) => s.isOpen);
  //   const setOpen = useDrawerStore((s) => s.setIsOpen);

  const [drawerComponent, setDrawerComponent] = React.useState(
    /**
     * @type {import('react').ReactNode}
     */
    (null)
  );
  return (
    <drawerContext.Provider value={{ setDrawerComponent }}>
      <Drawer
        open={drawerComponent !== null}
        onClose={() => setDrawerComponent(null)}
        anchor='right'
        sx={{
          zIndex: 1300,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            minWidth: '10px',
            boxSizing: 'border-box',
          },
        }}
      >
        {drawerComponent}
      </Drawer>
      {children}
    </drawerContext.Provider>
  );
}
