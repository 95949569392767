import { createContext } from 'react';

interface TSidebarContext {
  isOpen: boolean;
  setIsOpen(isOpen: boolean): void;
}

export const SidebarContext = createContext<TSidebarContext>({
  isOpen: false,
  setIsOpen: () => {},
});
