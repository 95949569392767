import './UserProfileDropdown.scss';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { Logout } from '@/components/icons/Icons';
import { getUserData } from '../../utils/okta.js';

const getInitials = (fname, lname) => {
  const first = fname ? fname[0] : '';
  const last = lname ? lname[0] : '';
  return `${first}${last}`;
};

const UserProfileDropdown = React.forwardRef((props, ref) => {
  const user = getUserData();

  const navigate = useNavigate();

  const logoutHandler = () => {
    navigate('/logout/oidc/callback');
  };

  const initials = getInitials(user.firstname, user.lastname);

  return (
    <Menu
      id='basic-menu'
      anchorEl={props.anchorEl}
      open={props.open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClose={props.hideDropdown}
      className={'user-profile-dropdown'}
      sx={{ pointerEvents: 'none', marginTop: '8px' }}
      PaperProps={{
        sx: {
          pointerEvents: 'auto',
        },
      }}
    >
      <div ref={ref}>
        <Box
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '320px',
            minWidth: '320px',
          }}
        >
          <Avatar sx={{ bgcolor: '#003F2D' }}>{initials}</Avatar>
          <Box sx={{ marginLeft: '16px' }}>
            <Typography
              variant='h6'
              sx={{ letterSpacing: 0 }}
            >{`Welcome, ${user.firstname}!`}</Typography>
            <Typography
              variant='subtitle2'
              sx={{ color: '#1A1A1A99', letterSpacing: 0 }}
            >
              {user.email}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <MenuItem onClick={() => logoutHandler()}>
          <div className='user-profile-dropdown-menu-item'>
            <Logout
              sx={{
                fontSize: '18px',
                color: '#1A1A1A',
                opacity: 0.6,
                marginLeft: '5px',
              }}
            />
            <span className='user-profile-dropdown-menu-item-text'>
              Log out
            </span>
          </div>
        </MenuItem>
      </div>
    </Menu>
  );
});
export default UserProfileDropdown;
