import './UserAvatar.scss';

import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import OktaAuth from '../../utils/okta.js';
import GlobalState from '../../store.js';
import Api from '../../utils/Api.js';
import { Box } from '@mui/material';

import { getUserData } from '../../utils/okta.js';

import UserProfileDropdown from './UserProfileDropdown.jsx';
import { getFeatureFlag } from '@/utils/getFeatureFlag';

const getInitials = (fname, lname) => {
  const first = fname ? fname[0] : '';
  const last = lname ? lname[0] : '';
  return `${first}${last}`;
};

export function UserAvatar() {
  const { header: isNewHeaderEnabled } = getFeatureFlag('smartfm2.0');
  const [hasProfilePic] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const dropdownAnchor = useRef();
  const dropdownMenuRef = useRef();
  const user = getUserData();

  const getApplicationList = async () => {
    try {
      const oktaToken = JSON.parse(localStorage.getItem('okta-token-storage'));

      const response = await Api.get(`/getProjectsByUser/${user.aud}`);

      if (response !== '') {
        const globalState = GlobalState.getGlobalState();
        const responseList = response.data;
        const idx = responseList.findIndex(
          subArray => subArray.isSelectedProject === true
        );
        const isListed = responseList
          .map(subArray => subArray.project_name)
          .includes(globalState.project);

        if (!isListed) {
          globalState.project = response.data[idx].project_name;
          GlobalState.setGlobalState(globalState);
        }

        setProjectList(responseList);
      } else {
        setProjectList([]);
      }
    } catch (error) {
      setProjectList([]);
    }
  };

  useEffect(() => {
    getApplicationList();
  }, []);

  const navigate = useNavigate();

  const logoutHandler = () => {
    OktaAuth.logout();
    navigate('/logout/oidc/callback');
  };

  const toggleOpen = () => {
    setIsActive(!isActive);
  };

  const showDropdown = event => {
    setDropdownOpen(true);
  };

  const hideDropdown = () => {
    setDropdownOpen(false);
  };

  useEffect(() => {
    if (isActive) {
      showDropdown();
    } else {
      hideDropdown();
    }

    function handleClickOutside(event) {
      if (
        dropdownMenuRef.current &&
        dropdownMenuRef.current !== null &&
        !event.target.classList.contains('MuiAutocomplete-option') &&
        !dropdownAnchor.current.contains(event.target) &&
        !dropdownMenuRef.current.contains(event.target)
      ) {
        setIsActive(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isActive]);

  const initials = getInitials(user.firstname, user.lastname);
  const id = dropdownOpen ? 'user-profile-popover' : undefined;

  const avatarCircleClass = dropdownOpen
    ? 'user-profile-avatar-circle-shadow'
    : 'user-profile-avatar-circle';

  return (
    <>
      {isNewHeaderEnabled ? (
        <Box
          onClick={() => toggleOpen()}
          sx={{ padding: 1.25, cursor: 'pointer' }}
        >
          <Box
            aria-describedby={id}
            ref={dropdownAnchor}
            sx={{
              backgroundColor: '#fff',
              height: 24,
              width: 24,
              borderRadius: 12,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              opacity: 0.6,
            }}
          >
            <Box sx={{ fontSize: '0.75rem' }}>{initials}</Box>
          </Box>
        </Box>
      ) : (
        <div
          className='user-profile-avatar'
          onClick={() => toggleOpen()}
          aria-describedby={id}
          ref={dropdownAnchor}
        >
          <div className={avatarCircleClass}>
            <span className='user-profile-avatar-initials'>{initials}</span>
          </div>
        </div>
      )}

      <div style={{ display: 'flex' }}>
        <UserProfileDropdown
          ref={dropdownMenuRef}
          id={id}
          open={dropdownOpen}
          anchorEl={dropdownAnchor.current}
          hideDropdown={hideDropdown}
        />
      </div>
    </>
  );
}
