import { loadRemote } from '@module-federation/runtime';
import type { ComponentType } from 'react';
import { lazy } from 'react';

async function loadAssetsAppComponent() {
  const module = await loadRemote<{ default: ComponentType }>('assets-ui/App');
  return module ?? { default: () => null };
}

export const Assets = lazy(loadAssetsAppComponent);
