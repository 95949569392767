import { type EmeraldButtonInputs, EmeraldButton } from '@emerald-react/button';
import type { EmeraldIconTypes } from '@emerald/nxcore';
import type { ReactNode } from 'react';

// Fix layout shift on hover in button of type: 'Light'
import './styles.scss';

export interface ButtonProps
  extends Omit<EmeraldButtonInputs, 'icon' | 'leftIcon' | 'rightIcon'> {
  icon?: EmeraldIconTypes;
  leftIcon?: EmeraldIconTypes | Exclude<ReactNode, string>;
  rightIcon?: EmeraldIconTypes | Exclude<ReactNode, string>;
}

export function Button(props: ButtonProps) {
  return <EmeraldButton {...props} />;
}
