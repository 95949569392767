import AssetSummaryWidget from '@/components/widgets/AssetSummary/AssetSummaryWidget.jsx';
import { default as AssetHealthWidget } from '../components/cards/AssetHealth/AssetHealth.jsx';
// import { default as AssetIssuesWidget } from '../components/cards/AssetIssues/AssetIssues.jsx';
import BuildingInfo from '../components/cards/BuildingInfo/BuildingInfo.jsx';
import { default as LegionellaWidget } from '../components/cards/Legionella/Legionella.jsx';
import { default as WeatherWidget } from '../components/cards/Weather/Weather.jsx';
import { IssuesWidget } from '../components/widgets/IssuesWidget/IssuesWidget.jsx';
import { AssetTypesUnresolvedIssuesWidget } from '@/components/widgets/AssetTypesUnresolvedIssuesWidget';
import TopRecurringIssuesWidget from '@/components/widgets/IssuesWidget/RecurringIssuesWidget.jsx';
// Static widgets
// import { default as ServiceRequests } from '../components/cards/static/ServiceRequests/ServiceRequests.jsx';

export const category = {
  Assets: 'Assets',
  // Maintenance: 'Maintenance',
  'Pipe Monitoring': 'Pipe Monitoring',
  // 'Dynamic services': 'Dynamic services',
  Weather: 'Weather',
  'Building Info': 'Building Info',
};

export const widgets = [
  //#2638685
  // {
  //   id: '1',
  //   type: 'column',
  //   content: <OccupancyWidget />,
  //   category: category['Dynamic services']
  // },
  // { id: '2', type: 'line', content: <EnergyWidget /> },
  {
    id: '2',
    type: 'table',
    content: <AssetSummaryWidget />,
    category: category.Assets,
  },
  {
    id: "3",
    type: "recurring-issues",
    content: <TopRecurringIssuesWidget />,
    category: category.Assets,
  },
  {
    id: "5",
    type: "pie",
    content: <LegionellaWidget />,
    category: category['Pipe Monitoring'],
  },
  {
    id: '8',
    type: 'pie',
    content: <AssetHealthWidget />,
    category: category.Assets,
  },
  {
    id: '9',
    type: 'bar',
    content: <IssuesWidget />,
    category: category.Assets,
  },
  {
    id: '10',
    type: 'list',
    content: <BuildingInfo />,
    category: category['Building Info'],
  },
  {
    id: '11',
    type: 'weather',
    content: <WeatherWidget />,
    category: category.Weather,
  },
  // { id: '12', type: 'pie', content: <ServiceRequests />,
  //  category: category['Legionella Monitoring'] },
  // { id: '13', type: 'pie', content: <WorkordersWidget />,
  //  category: category['Legionella Monitoring'] },
  //#2638685
  // {
  //   id: '14',
  //   type: 'pie',
  //   content: <MaintenanceWidget />,
  //   category: category.Maintenance
  // }
  // {
  //   id: '15',
  //   type: 'pie',
  //   content: <EnergyWidget />,
  //   category: category.Assets
  // },
  // {
  //   id: '16',
  //   type: 'pie',
  //   content: <WorkordersWidget />,
  //   category: category.Maintenance
  // },
  // {
  //   id: '17',
  //   type: 'pie',
  //   content: <CBMWidget />,
  //   category: category.Maintenance
  // },
  // {
  //   id: '18',
  //   type: 'pie',
  //   content: <FloorPlanWidget />,
  //   category: category.Maintenance
  // }
  // {
  //   id: '17',
  //   type: 'pie',
  //   content: <CBMWidget />,
  //   category: category.Maintenance
  // }
  // {
  //   id: '18',
  //   type: 'pie',
  //   content: <FloorPlanWidget />,
  //   category: category.Maintenance
  // }
  {
    id: '19',
    type: 'bar',
    content: <AssetTypesUnresolvedIssuesWidget />,
    category: category.Assets,
  },
];
