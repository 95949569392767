import type { ReactNode } from 'react';
import { ErrorBoundaryWithTheme } from './ErrorBoundaryWithTheme';
import { handleError } from './utils';
import { ErrorBoundary } from 'react-error-boundary';

function FallbackComponentWithoutDependency() {
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      Something went wrong.
    </div>
  );
}

export function AppErrorBoundary({ children }: { children: ReactNode }) {
  return (
    <ErrorBoundary
      FallbackComponent={FallbackComponentWithoutDependency}
      onError={handleError}
    >
      <ErrorBoundaryWithTheme isTopLevelBoundary>
        {children}
      </ErrorBoundaryWithTheme>
    </ErrorBoundary>
  );
}
