import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      refetchInterval: 1000 * 60 * 5,
      refetchIntervalInBackground: true,
    },
  },
});
