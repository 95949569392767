import './SideBar.scss';

import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { Link, useLocation } from 'react-router-dom';

import Api from '../../utils/Api.js';

import {
  AdminPanelSettings,
  ChevronLeft,
  ChevronRight,
  Dashboard,
} from '@/components/icons/Icons';

import { navigation } from '../../MicroApps.jsx';

import { useSidebarContext } from '@/context/sidebar';
import { getFeatureFlag } from '@/utils/getFeatureFlag';
import React, { useEffect, useState } from 'react';

function getItem(label, link, key, icon, children, category) {
  return {
    key,
    icon,
    children,
    label,
    category,
    link,
  };
}

const drawerWidth = 310;

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

function NavigationDrawer({ parentCallback, pathname, onCollapse }) {
  const { header: isNewHeaderEnabled } = getFeatureFlag('smartfm2.0');
  const { isOpen: open, setIsOpen: setOpen } = useSidebarContext();

  const [navItems, setNavItems] = useState(false);
  const [applicationList, setapplicationList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const [categories, setCategories] = useState([]);
  const theme = useTheme();
  const [selected, setSelected] = useState(window.location.pathname);
  const location = useLocation();

  const getSubNavigation = async applicationList => {
    const _navigation = { ...navigation };
    const children = null;
    applicationList.forEach(ni => {
      const path = ni.key;
      //let navKey = microApps.filter((row) => row.navPath == path)[0]?.key;

      // if (navKey in _navigation) {
      //   children = Object.assign([], _navigation[navKey]);
      // }
    });
    const newItems = Object.assign(applicationList, {});
    //newItems[1].children = children;
    return newItems;
  };

  useEffect(() => {
    getSubNavigation(applicationList).then(newNav => setNavItems(newNav));
    const itemCategories = applicationList.map(i => i.category);
    setCategories([...new Set(itemCategories)]);
    getApplicationList();
    parentCallback(open);
  }, []);
  const onItemChange = () => {
    setCurrentPath(window.location.pathname);
  };

  useEffect(() => {
    setSelected(location.pathname);
  }, [location]);

  const getApplicationList = async () => {
    try {
      setIsLoading(true);
      const oktaToken = JSON.parse(localStorage.getItem('okta-token-storage'));

      const response = await Api.get('/getUserPermissions');

      if (response != '') {
        const userIsAdmin = JSON.parse(localStorage.getItem('is-admin'));
        if (response.data.isAdmin) {
          localStorage.setItem(
            'is-admin',
            JSON.stringify(+response.data.isAdmin)
          );
        } else {
          localStorage.removeItem('is-admin');
        }
        const newApplicationList = response.data.permissions.directAccess.map(
          applications => {
            return getItem(
              applications.application_name,
              `/${
                applications.nav_path === 'ui/smart-cleaning'
                  ? 'ui/smart-cleaning'
                  : applications.nav_path
              }`,
              applications.application_name,
              <span className='material-symbols-outlined'>
                {applications.icon_url}
              </span>,
              [],
              applications.category
            );
          }
        );

        newApplicationList.sort((a, b) => a.label.localeCompare(b.label));
        if (response.data.isAdmin === true) {
          newApplicationList.push(
            getItem(
              'Administration',
              '/ui/admin',
              'admin',
              <AdminPanelSettings />,
              [],
              'Admin'
            )
          );
        }

        setapplicationList(newApplicationList);
        setIsLoading(false);
        return response;
      }
      window.location.reload(true);
    } catch (error) {
      return {};
    }
  };

  const handleDrawer = () => {
    setOpen(!open);
    parentCallback(!open);
    localStorage.setItem('navdrawerstateopen', !open);
  };

  return (
    <Drawer
      variant='permanent'
      open={open}
    >
      <Toolbar
        sx={
          isNewHeaderEnabled
            ? theme => ({
                [theme.breakpoints.up('sm')]: {
                  minHeight: 56,
                },
              })
            : undefined
        }
      />
      <Link
        to={'/'}
        style={{ textDecoration: 'none' }}
      >
        <ListItem disablePadding>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
              // background: selected === navItem.link ? '#003d2c1f' : '#fff',
              mx: '7px',
              borderRadius: '4px',
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 1 : 'auto',
                justifyContent: 'center',
                color: '#1A1A1A',
              }}
            >
              {/* {navItem.icon} */}
              <Dashboard />
            </ListItemIcon>
            <ListItemText
              // id={`sidebar-app-redirect-text-${navItem.label}`}
              primary={'Dashboard'}
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
      </Link>
      <Divider
        sx={{
          my: '8px',
        }}
      />
      {applicationList.length > 0 ? (
        <List>
          {applicationList.map((navItem, index) => (
            <React.Fragment key={index}>
              {index == 0 ||
              navItem.category != applicationList[index - 1].category ? (
                open ? (
                  <ListItem
                    key={navItem.category}
                    disablePadding
                  >
                    <ListItemButton
                      id={`sidebar-app-redirect-${navItem.category}`}
                    >
                      <ListItemText
                        primary={navItem.category}
                        sx={{
                          fontSize: '14px',
                          color: 'rgba(26, 26, 26, 0.6)',
                          display: open ? 'block' : 'none',
                          textTransform: 'capitalize',
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ) : (
                  <Divider
                    sx={{
                      display: open || index === 0 ? 'none' : 'block',
                      my: '6px',
                    }}
                    key={index}
                  />
                )
              ) : (
                <></>
              )}
              {/* two possibilities... it's either / or /demo */}
              <Link
                key={`${index}-2`}
                to={
                  location.pathname?.startsWith('/demo')
                    ? `/demo${navItem.link}`
                    : `${navItem.link}`
                }
                onClick={() => setSelected(navItem.link)}
                style={{ textDecoration: 'none' }}
              >
                <ListItem
                  key={navItem.key}
                  disablePadding
                  sx={{
                    display: 'block',
                  }}
                >
                  <ListItemButton
                    id={`sidebar-app-redirect-list-${navItem.label}`}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      background:
                        selected === navItem.link ? '#003d2c1f' : '#fff',
                      mx: '7px',
                      borderRadius: '4px',
                    }}
                  >
                    <ListItemIcon
                      id={`sidebar-app-redirect-${navItem.label}`}
                      sx={{
                        minWidth: 0,
                        mr: open ? 1 : 'auto',
                        justifyContent: 'center',
                        color: '#1A1A1A',
                      }}
                    >
                      {navItem.icon}
                    </ListItemIcon>
                    <ListItemText
                      id={`sidebar-app-redirect-text-${navItem.label}`}
                      primary={navItem.label}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
                <Divider />
              </Link>
            </React.Fragment>
          ))}
        </List>
      ) : (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='20vh'
        >
          {isLoading ? (
            <CircularProgress size='1rem' />
          ) : (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              minHeight='20vh'
              fontSize='9px'
            >
              Sorry! you do not have access for any applications.
            </Box>
          )}
        </Box>
      )}

      <DrawerHeader>
        <IconButton
          onClick={handleDrawer}
          id='sidebar-toggle'
        >
          {open ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </DrawerHeader>
    </Drawer>
  );
}

export default NavigationDrawer;
