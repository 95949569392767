import { EmeraldAppHeader } from '@emerald-react/app-header';
import { Box } from '@smartfm/ui/components/Box';
import { useState, type ComponentProps } from 'react';

// This file contains fixes for style inconsistencies in EmeraldAppHeader component
import './styles.scss';
import { Button } from '@mui/material';
import { throwUsingLocalStorageTestConfig } from '@/components/AppErrorBoundary/utils';

type EmeraldAppHeaderProps = ComponentProps<typeof EmeraldAppHeader>;

interface AppHeaderProps extends Omit<EmeraldAppHeaderProps, 'logo' | 'style'> {
  activeAppName?: string;
}

export function AppHeader(props: AppHeaderProps) {
  const [showError, setShowError] = useState(false);
  const { activeAppName } = props;
  const logo = (
    <>
      CBRE Smart FM <Box sx={{ paddingX: 2 }}>|</Box> {activeAppName || 'Home'}
      {window.localStorage.getItem('test-config') ? (
        <Button
          onClick={() => {
            setShowError(v => !v);
          }}
        >
          throw error
        </Button>
      ) : null}
    </>
  );

  return showError ? (
    (throwUsingLocalStorageTestConfig(), null)
  ) : (
    <EmeraldAppHeader
      logo={logo}
      {...props}
    />
  );
}
