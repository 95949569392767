import { to } from '@/utils/to';
import { useQuery } from '@tanstack/react-query';
import { api as bffHttpClient } from '@/utils/Api';
import { getUniqueObjects } from '@/utils/functions';

export interface App {
  user_id: string;
  project_id: number;
  application_id: number;
  project_name: string;
  application_name: string;
  icon_url: string;
  nav_path: string;
  category: string;
  dis: string;
}

interface Client {
  id: string;
  name: string;
  apps: App[];
}

export interface GetProjectsResponse {
  data: Client[] | null;
}

export const queryKey = 'projects';

export function useProjects<TSelector = GetProjectsResponse>(
  select?: (data: GetProjectsResponse) => TSelector
) {
  return useQuery<GetProjectsResponse, Error, TSelector>({
    queryFn: async () => {
      const [data, error] = await to(
        bffHttpClient.get<GetProjectsResponse>('/navigation/projects')
      );

      if (error) throw error;
      return data.data;
    },
    queryKey: [queryKey],
    select,
  });
}

export function getAvailableAppsForClient(
  response: GetProjectsResponse,
  clientId: string
) {
  const client = response.data?.find(client => client.id === clientId);
  if (!client) {
    return [];
  }

  return getUniqueObjects(client.apps, 'application_id');
}
