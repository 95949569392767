import { ErrorCardContent } from '@/components/cards/ErrorCardContent';
import TimePicker from '@/components/cards/TimePicker/TimePicker.jsx';
import { useNavigationCtx } from '@/components/NavigationProvider';
import AhsCell from '@/components/table/AssetHealth.jsx';
import AssetTrendCell from '@/components/table/AssetTrendCell';
import EnhancedTable from '@/components/table/EnhancedTable.jsx';
import IssueCell from '@/components/table/IssueCell.jsx';
import EnhancedTableToolbar from '@/components/table/Toolbar';
import { useDashboardContext } from '@/context/dashboard';
import { getAssets, getHistoricalIssues } from '@/services/DashboardService';
import { timeDurationOptions } from '@/utils/constants.js';
import { formatNumber, normalizeValue } from '@/utils/functions';
import { Link, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const dateSelectorOptions = timeDurationOptions.find(
  v => v.label === 'Last month'
);

const getDateRange = option => {
  const today = dayjs();

  switch (option) {
    case 'thisMonth':
      return {
        start: today.startOf('month').format('YYYY-MM-DD'),
        end: today.endOf('month').format('YYYY-MM-DD'),
      };

    case 'thisWeek':
      return {
        start: today.startOf('week').format('YYYY-MM-DD'),
        end: today.endOf('week').format('YYYY-MM-DD'),
      };

    case 'lastMonth':
      return {
        start: today.subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
        end: today.subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
      };

    case 'lastWeek':
      return {
        start: today.subtract(1, 'week').startOf('week').format('YYYY-MM-DD'),
        end: today.subtract(1, 'week').endOf('week').format('YYYY-MM-DD'),
      };
    case 'last30Days':
      return {
        start: today.subtract(30, 'days').format('YYYY-MM-DD'),
        end: today.format('YYYY-MM-DD'),
      };

    default:
      return null;
  }
};

const WidgetCard = ({ top, scrollContainer }) => (
  <div
    className='widget'
    style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      padding: 0,
    }}
  >
    <div
      className='top'
      style={{ height: '65px' }}
    >
      {top}
    </div>
    <div
      className='bottoms'
      style={{ height: '100%', overflow: 'hidden' }}
    >
      {scrollContainer}
    </div>
  </div>
);
WidgetCard.propTypes = {
  top: PropTypes.node,
  scrollContainer: PropTypes.node,
};

const NavLink = ({ name, id, interval }) => {
  const navigate = useNavigate();
  const intervalDate = getDateRange(interval);
  const assetLink = useMemo(() => {
    return `/ui/cbm?selectedView=equip&selectedFilter=All+Asset+Types&recordId=${id}&recordType=equip&pageNumber=0&tab=Assets&intervalType=Monthly&intervalStart=${intervalDate?.start}T05%3A00%3A00.000Z&assetView=cost`;
  }, [id, intervalDate]);
  if (id) {
    return (
      <Link
        sx={{
          color: '#003F2D',
          textDecoration: 'underline',
          '&:hover': {
            cursor: 'pointer',
            textDecorationColor: '#17e88f',
          },
        }}
        onClick={e => {
          e.preventDefault();
          navigate(assetLink);
        }}
      >
        {name}
      </Link>
    );
  }
  return name;
};

NavLink.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  interval: PropTypes.string,
};
const ToolbarActions = ({ setInterval }) => {
  const handleDateSelect = useCallback(
    dateRange => {
      const dateRangeValue = timeDurationOptions.find(
        duration => duration.label === dateRange
      );
      setInterval(dateRangeValue.value);
    },
    [setInterval]
  );
  return (
    <Stack
      direction='row'
      spacing={2}
      alignItems='center'
    >
      <IssueCell
        high={'P1'}
        medium={'P2'}
        low={'P3'}
      />
      <TimePicker
        defaultVal={dateSelectorOptions.label}
        onChangeDateRange={newDateRange => handleDateSelect(newDateRange)}
        timeDurationOptions={timeDurationOptions}
        sx={{
          backgroundColor: '#003F2D14',
          color: '#003F2D',
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '16px',
          boxShadow: 'none',
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
          height: '30px',
          minWidth: '8rem',
        }}
      />
    </Stack>
  );
};

ToolbarActions.propTypes = {
  setInterval: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const TopAssetsWidget = () => {
  const [interval, setInterval] = useState(dateSelectorOptions.value);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowPerpage] = useState(10);
  const [selected, setSelected] = useState([]);
  const { refreshDuration, refreshEvent } = useDashboardContext();
  const navigationCtx = useNavigationCtx();
  const projectId = navigationCtx?.state.selectedOption?.project_nexus_id;
  const buildingId = navigationCtx?.state.selectedOption?.nexusId;
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('asset_health_score');
  const issuesWidgetType = 'assetsHistoricalIssues';
  const assetsWidgetType = 'assetHealthList';

  const columns = [
    {
      id: 'asset_name',
      label: 'Asset',
      numeric: false,
      disablePadding: false,
      style: { width: '18%' },
    },
    {
      id: 'issue_priority',
      label: 'Issues',
      numeric: false,
      disablePadding: false,
      style: { width: '18%' },
      disablePadding: true,
    },
    {
      id: 'asset_health_score',
      label: 'Lowest Health Status',
      numeric: false,
      disablePadding: false,
      style: { width: '18%' },
    },
    {
      id: 'cost_impact',
      label: 'Cost Impact (USD)',
      numeric: true,
      disablePadding: false,
      align: 'left',
      style: { width: '15%', paddingLeft: '6.5rem' },
    },
    {
      id: 'asset_trend',
      label: 'Cost Impact Trend (USD)',
      numeric: false,
      disablePadding: false,
      style: { width: '20%' },
    },
  ];

  const {
    data: historicalIssues,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['historicalIssues', projectId, buildingId, refreshEvent],
    enabled: Boolean(projectId) && Boolean(buildingId),
    queryFn: () =>
      getHistoricalIssues(projectId, buildingId, issuesWidgetType, interval),
    refetchInterval: 1000 * parseInt(refreshDuration) * 60,
  });

  const {
    data: historicalAssetsHealth,
    isLoading: isLoadingAssets,
    refetch: refetchAssets,
  } = useQuery({
    queryKey: ['historicalAssets', projectId, buildingId, refreshEvent],
    enabled: Boolean(projectId) && Boolean(buildingId),
    queryFn: () => getAssets(projectId, buildingId, assetsWidgetType, interval),
    refetchInterval: 1000 * parseInt(refreshDuration) * 60,
  });

  const handleChangePage = (event, newPage) => {
    event.stopPropagation();
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    event.stopPropagation();
    setRowPerpage(event.target.value);
  };
  const descendingComparator = (a, b, orderBy) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    // Fast path for null/undefined
    if (!bValue) return -1;
    if (!aValue) return 1;

    // Fast path for non-objects
    if (typeof aValue !== 'object' || typeof bValue !== 'object') {
      return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
    }

    // For objects, use JSON.stringify for fast comparison if objects are identical
    if (JSON.stringify(aValue) === JSON.stringify(bValue)) return 0;

    // Quick numeric comparison for object values
    let comparison = 0;
    const aKeys = Object.keys(aValue);

    // Only iterate through keys that exist in first object for speed
    for (let i = 0; i < aKeys.length && comparison === 0; i++) {
      const key = aKeys[i];
      comparison = (bValue[key] || 0) - (aValue[key] || 0);
    }

    return comparison;
  };

  const getComparator = useCallback((order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }, []);

  const assetsData = useCallback(() => {
    const historicalIssuesToSeverity = (historicalValues, assetName) => {
      const severity = {
        high: 0,
        medium: 0,
        low: 0,
      };
      historicalValues?.forEach(value => {
        const issueData = value?.issue_data;
        if (normalizeValue(value?.asset_name) === assetName) {
          issueData?.forEach(issue => {
            if (issue?.issue_priority === 'P1') {
              severity.high += issue.issue_count;
            } else if (issue?.issue_priority === 'P2') {
              severity.medium += issue.issue_count;
            } else if (issue?.issue_priority === 'P3') {
              severity.low += issue.issue_count;
            }
          });
        }
      });

      return severity;
    };
    const assetsCostImpact =
      historicalIssues?.data?.values?.assetsCostImpacts || [];
    const assetsHistoricalIssues =
      historicalIssues?.data?.values?.assetsHistoricalIssues || [];
    const assetHealthScore = historicalAssetsHealth?.data?.values;
    const assetsHistoricalData = assetsCostImpact?.map?.(asset => {
      const assetName = normalizeValue(asset.asset_name);
      const healthScore = assetHealthScore?.find?.(
        health => normalizeValue(health.asset_name) === assetName
      );
      const assetIssueSeverity = historicalIssuesToSeverity(
        assetsHistoricalIssues,
        assetName
      );
      return {
        asset_name: assetName?.toUpperCase(),
        asset_id: healthScore?.asset_id || asset.asset_name,
        cost_impact: asset.cost_impact,
        issue_priority: assetIssueSeverity,
        asset_health_score: healthScore?.asset_health_score || 1000,
        asset_trend: asset.deviation,
      };
    });
    const sortedAssets = assetsHistoricalData.sort(
      getComparator(order, orderBy)
    );
    return sortedAssets;
  }, [historicalIssues, historicalAssetsHealth, getComparator, order, orderBy]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = rows.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  useEffect(() => {
    if (refetch && refetchAssets) {
      refetch();
      refetchAssets();
      setPage(0);
    }
  }, [interval, refetch, refetchAssets]);

  const rows = useMemo(() => {
    const rowArray = [];
    if (historicalIssues?.status === 'success') {
      const assets = assetsData();
      assets?.forEach(asset => {
        rowArray.push({
          asset_name: (
            <NavLink
              name={asset.asset_name}
              id={asset?.asset_id}
              interval={interval}
            />
          ),
          asset_id: asset.asset_id || asset.asset_name,
          issue_priority: (
            <IssueCell
              high={asset.issue_priority?.high}
              medium={asset.issue_priority?.medium}
              low={asset.issue_priority?.low}
            />
          ),
          cost_impact: formatNumber(asset.cost_impact, {
            style: 'currency',
            currency: 'USD',
          }),
          asset_health_score: (
            <AhsCell
              healthScore={asset?.asset_health_score}
              isLoading={isLoadingAssets}
            />
          ),
          asset_trend: <AssetTrendCell deviation={asset?.asset_trend} />,
        });
      });
    }
    return rowArray;
  }, [assetsData, historicalIssues, isLoadingAssets, interval]);

  const WidgetErrorReload = ({ onReload }) => (
    <div
      className='top'
      style={{ height: '100%' }}
    >
      <div className='top'>{/* <WidgetCardTitle /> */}</div>
      <ErrorCardContent onReload={onReload} />
    </div>
  );

  WidgetErrorReload.propTypes = {
    onReload: PropTypes.func,
  };

  const visibleRows = useMemo(
    () => [...rows].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [rows, page, rowsPerPage]
  );

  if (isError) return <WidgetErrorReload onReload={refetch} />;

  return (
    <WidgetCard
      top={
        <EnhancedTableToolbar
          tableName={'Assets Issue Summary'}
          sx={{
            backgroundColor: '#fff',
            // border: "1px solid #E0E0E0",
            borderBottom: 0,
            // borderRadius: "4px",
          }}
        >
          {
            <ToolbarActions
              setInterval={setInterval}
              disabled={!rows.length}
            />
          }
        </EnhancedTableToolbar>
      }
      scrollContainer={
        <EnhancedTable
          isLoading={isLoading}
          columns={columns}
          order={order}
          orderBy={orderBy}
          pagination={{
            page,
            pageHandler: handleChangePage,
            rowsPerPage,
            rowsPerPageHandler: handleChangeRowsPerPage,
            totalRows: rows.length,
          }}
          requestSort={handleRequestSort}
          rows={visibleRows}
          selectAllClick={handleSelectAllClick}
          selected={selected}
        />
      }
    />
  );
};

export default TopAssetsWidget;
