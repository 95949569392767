import {
  EmeraldDivider,
  type EmeraldDividerInputs,
} from '@emerald-react/divider';
import './styles.css';

export type DividerProps = EmeraldDividerInputs;

export function Divider(props: DividerProps) {
  return <EmeraldDivider {...props} />;
}
