const ellipsis = '…';

export function truncateWithEllipsis(input: string, maxLength: number) {
  if (input.length <= maxLength) return input;

  const visibleChars = Math.floor((maxLength - ellipsis.length) / 2);
  if (visibleChars <= 0) return ellipsis;

  return input.slice(0, visibleChars) + ellipsis + input.slice(-visibleChars);
}
