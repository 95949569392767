import Api, { api } from '../utils/Api.js';

export async function getInterval() {
  try {
    const response = await Api.get('dashboard/interval');
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function saveRefreshInterval(intervalInMins) {
  try {
    const data = JSON.stringify({
      interval: intervalInMins,
    });
    const response = await Api.post('dashboard/interval', data);
    return response;
  } catch (error) {
    return [];
  }
}
/**
 *
 * @param {string} client
 * @param {string} siteId
 */
export async function setDefaultDashboard(client, siteId) {
  try {
    const data = JSON.stringify({
      client,
      siteId,
      defaultDashboard: true,
    });
    /** @type {import("axios").AxiosResponse<{message:string;error:boolean}>} */
    const response = await Api.post('AddUserDefaultDashboard', data);
    return response.data;
  } catch (error) {
    return [];
  }
}

/**
 *
 * @param {string} client
 */
export async function getWidgetListByClient(client) {
  try {
    /** @type {import("axios").AxiosResponse<{message:string;error:boolean}>} */
    if (!client) throw new Error('Client is required');
    const response = await Api.get(`getWidgetList/${client}`);
    return response.data;
  } catch (error) {
    return [];
  }
}

function handleResponse(response) {
  if (response.data.status === 'error')
    if (response.data.errorMessage)
      return {
        status: 'error',
        errorMessage:
          response.data.errorMessage === 'No data for date range' ||
          response.data.errorMessage === 'No data for record'
            ? response.data.errorMessage
            : 'unknown error',
      };
    else
      throw new Error(
        "API response status is 'error' without any 'errorMessage'"
      );
  if (!response.data.data)
    return {
      status: 'error',
      errorMessage: 'No data for record',
    };
  return {
    status: 'success',
    data: response.data.data,
  };
}

/**
 * Get all the historical issues of a project based on the project id and building id and widget type
 * @param {string} projectId
 * @param {string} buildingId
 * @param {string} widgetType
 * @param {string} interval
 * @returns {Promise<{status:string,data?:any,errorMessage?:string}>}
 */
export async function getHistoricalIssues(
  projectId,
  buildingId,
  widgetType,
  interval
) {
  try {
    const response = await api.get(
      `issues/projects/${projectId}/buildings/${buildingId}/widgets`,
      {
        params: {
          widgetType,
          interval,
        },
      }
    );
    return handleResponse(response);
  } catch (error) {
    return {
      status: 'error',
      errorMessage: error.message,
    };
  }
}

export async function getAssets(projectId, buildingId, widgetType, interval) {
  try {
    const response = await api.get(
      `assets/projects/${projectId}/buildings/${buildingId}/widgets`,
      {
        params: {
          widgetType,
          interval,
        },
      }
    );
    return handleResponse(response);
  } catch (error) {
    return {
      status: 'error',
      errorMessage: error.message,
    };
  }
}

export async function getTop10RecurringIssues(
  projectId,
  buildingId,
  widgetType,
  interval,
) {
  try {
    const response = await api.get(
      `issues/projects/${projectId}/buildings/${buildingId}/widgets`,
      {
        params: {
          widgetType,
          interval,
        },
      },
    );
    return handleResponse(response);
  } catch (error) {
    return {
      status: "error",
      errorMessage: error.message,
    };
  }
}
