import { Skeleton, Stack, Typography } from '@mui/material';
import {
  FairHealthFace,
  GoodHealthFace,
  PoorHealthFace,
} from '@/components/icons/Icons';
import PropTypes from 'prop-types';
import useStyles from './useStyles';
import { Warning } from '@mui/icons-material';

function AhsCell({ healthScore, isLoading = false }) {
  const classes = useStyles();
  const healthElement = {
    icon: <></>,
    text: 'N/A',
  };
  if (healthScore >= 66 && healthScore <= 100) {
    healthElement.icon = <GoodHealthFace />;
    healthElement.text = 'Good';
  } else if (healthScore < 66 && healthScore >= 33) {
    healthElement.icon = <FairHealthFace />;
    healthElement.text = 'Fair';
  } else if (healthScore < 33) {
    healthElement.icon = <PoorHealthFace />;
    healthElement.text = 'Poor';
  } else {
    healthElement.icon = <Warning fontSize='8' />;
    healthElement.text = 'N/A';
  }
  return (
    <Stack
      direction='row'
      spacing={1}
      className={classes.healthScoreCell}
    >
      {isLoading ? (
        <>
          <Skeleton
            variant='circular'
            width={20}
            height={20}
          />
          <Skeleton
            variant='text'
            width={40}
            height={20}
          />
        </>
      ) : (
        <>
          {healthElement.icon}
          <Typography style={{ color: '#1A1A1A', fontSize: '14px' }}>
            {healthElement.text}
          </Typography>
        </>
      )}
    </Stack>
  );
}
AhsCell.propTypes = {
  healthScore: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
};
export default AhsCell;
