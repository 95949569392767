import { Icon } from '@smartfm/ui/components/Icon';

interface AppIconProps {
  icon: string;
}

export function AppIcon(props: AppIconProps) {
  if (props.icon === 'warning') {
    return (
      <Icon
        icon={props.icon}
        type='rounded'
      />
    );
  }

  return (
    <span className='material-symbols-outlined material-symbols-filled'>
      {props.icon}
    </span>
  );
}
