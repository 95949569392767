import './AppLayout.scss';

import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Box, Toolbar } from '@mui/material';
import LegacySideBar from './LegacySideBar.jsx';
import SideBar from './SideBar.tsx';

import FeedbackDrawer from '@/components/layouts/FeedbackDrawer';
import HTMLHead from './HTMLHead';
import LegacyTopAppBar from './TopAppBar';

import NoInternet from '@/components/noInternet/NoInternet';
import { useSidebarContext } from '@/context/sidebar';
import { getFeatureFlag } from '@/utils/getFeatureFlag';
import { AppHeader } from '@smartfm/container/components/AppHeader';
import { useDrawerContext } from './DrawerProvider';

import { useActiveAppName } from '@/hooks/useActiveAppName';
import { ClientBuildingSelector } from '@smartfm/container/components/ClientBuildingSelector';
import { IconButton } from '@smartfm/ui/components/IconButton';
import { Stack } from '@smartfm/ui/components/Stack';
import { CascaderInstance } from '../CascaderInstance.jsx';
import { UserAvatar } from '../profile/UserAvatar';

export function AppLayout() {
  const {
    header: isNewHeaderEnabled,
    clientBuildingSelector: isClientBuildingSelectorEnabled,
  } = getFeatureFlag('smartfm2.0');
  const moduleFederationRoutes = getFeatureFlag('moduleFederationRoutes');
  const { setDrawerComponent } = useDrawerContext();
  const navigate = useNavigate();

  const activeAppName = useActiveAppName();
  const location = useLocation();
  const pathname = location.pathname;
  const { isOpen: open } = useSidebarContext();
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [collapsed, setCollapsed] = useState(!open);
  const isModuleFederationRoute =
    (moduleFederationRoutes.cbm && pathname.includes('/ui/cbm')) ||
    (moduleFederationRoutes.admin && pathname.includes('/ui/admin'));

  useEffect(() => {
    function handleOffline() {
      setIsOnline(window.navigator.onLine);
    }

    function handleOnline() {
      window.location.reload();
    }

    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  const drawerCallback = drawerStatus => {
    setCollapsed(drawerStatus);
  };

  function handleFeedbackClick() {
    setDrawerComponent(<FeedbackDrawer />);
  }

  function handleLogoClick() {
    const path = location.pathname.startsWith('/demo') ? '/demo' : '/';
    navigate(path);
  }

  return (
    <>
      <HTMLHead />
      <Box sx={{ height: '100%', position: 'relative' }}>
        {isNewHeaderEnabled ? (
          <Box sx={{ position: 'fixed', zIndex: 1, left: 0, right: 0 }}>
            <AppHeader
              activeAppName={activeAppName}
              onLogoClick={handleLogoClick}
              customItems={[
                <Stack
                  key='stack'
                  direction='row'
                  alignItems='center'
                  gap={1}
                  useFlexGap={true}
                >
                  {isClientBuildingSelectorEnabled && (
                    <ClientBuildingSelector />
                  )}
                  <IconButton
                    key='feedback'
                    icon='feedback'
                    type='Icon'
                    shape='Circle'
                    onClick={handleFeedbackClick}
                  />
                  <Box
                    key='avatar'
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: 36,
                      width: 36,
                      color: 'primary.dark',
                    }}
                  >
                    <UserAvatar />
                  </Box>
                </Stack>,
              ]}
            />

            {/* TODO: its a temporary workaround to correctly setup the navigation state when you directly open any sub application. It will be implemented properly when we remove CascaderInstance from each sub application.
            Dashboard (path "/") already has this CascaderInstance, so no reason to load it twice. */}
            {pathname !== '/' && (
              <div style={{ display: 'none' }}>
                <CascaderInstance />
              </div>
            )}
          </Box>
        ) : (
          <LegacyTopAppBar />
        )}
        {isOnline ? (
          <Box
            sx={{
              height: '100%',
              position: 'relative',
              zIndex: 0,
              display: 'flex',
            }}
          >
            {getFeatureFlag('smartfm2.0').newSideBar ? (
              <SideBar />
            ) : (
              <LegacySideBar
                parentCallback={drawerCallback}
                collapsed={collapsed}
                pathname={pathname}
              />
            )}

            <Box
              component='main'
              sx={{
                flexGrow: 1,
                p: 0,
              }}
            >
              <Toolbar
                sx={
                  isNewHeaderEnabled
                    ? theme => ({
                        [theme.breakpoints.up('sm')]: {
                          minHeight: 48,
                        },
                      })
                    : undefined
                }
              />
              {!isModuleFederationRoute &&
              (pathname.startsWith('/ui') ||
                pathname.startsWith('/demo/ui')) ? (
                <main
                  className='subapp-container'
                  id='subapp-container'
                />
              ) : (
                <Outlet />
              )}
            </Box>
          </Box>
        ) : (
          <NoInternet />
        )}
      </Box>
    </>
  );
}
