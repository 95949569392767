import { loadRemote } from '@module-federation/runtime';
import type { ComponentType } from 'react';
import { lazy } from 'react';

async function loadAdminAppComponent() {
  const module = await loadRemote<{ default: ComponentType }>('admin-ui/App');
  return module ?? { default: () => null };
}

export const Admin = lazy(loadAdminAppComponent);
