import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link, Stack } from "@mui/material";
import { timeDurationOptions } from "@/utils/constants.js";
import TimePicker from "@/components/cards/TimePicker/TimePicker.jsx";
import EnhancedTable from "@/components/table/EnhancedTable.jsx";
import { useQuery } from "@tanstack/react-query";
import { useNavigationCtx } from "@/components/NavigationProvider";
import { useDashboardContext } from "@/context/dashboard";
import { formatNumber, normalizeValue } from "@/utils/functions";
import EnhancedTableToolbar from "@/components/table/Toolbar";
import PropTypes from "prop-types";
import { getTop10RecurringIssues } from "@/services/DashboardService";
import { useNavigate } from "react-router-dom";
import { ErrorCardContent } from "@/components/cards/ErrorCardContent";

const _timeDurationOptions = timeDurationOptions.filter(
  (v) =>
    v.label !== "Last 30 days" &&
    v.label !== "This week" &&
    v.label !== "Last week",
);
_timeDurationOptions.push(
  { label: "Last 3 Months", value: "last3Months" },
  { label: "Last 1 Year", value: "last1Year" },
);
const dateSelectorOptions = _timeDurationOptions.find(
  (v) => v.label === "Last 3 Months",
);

const WidgetCard = ({ top, scrollContainer }) => (
  <div
    className="widget"
    style={{
      display: "flex",
      flexDirection: "column",
      height: "100%",
      padding: 0,
    }}
  >
    <div className="top" style={{ height: "65px" }}>
      {top}
    </div>
    <div className="bottoms" style={{ height: "100%", overflow: "hidden" }}>
      {scrollContainer}
    </div>
  </div>
);
WidgetCard.propTypes = {
  top: PropTypes.node,
  scrollContainer: PropTypes.node,
};

const NavLink = ({ name, id, interval }) => {
  const navigate = useNavigate();
  const assetLink = useMemo(() => {
    return `/ui/cbm?selectedView=equip&selectedFilter=All+Asset+Types&recordId=${id}&recordType=equip&pageNumber=0&tab=Assets&intervalType=Monthly`;
  }, [id]);
  if (id) {
    return (
      <Link
        sx={{
          color: "#003F2D",
          textDecoration: "underline",
          "&:hover": {
            cursor: "pointer",
            textDecorationColor: "#17e88f",
          },
        }}
        onClick={(e) => {
          e.preventDefault();
          navigate(assetLink);
        }}
      >
        {name}
      </Link>
    );
  } else {
    return name;
  }
};

NavLink.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  interval: PropTypes.string,
};

const ToolbarActions = ({ setInterval }) => {
  const handleDateSelect = useCallback(
    (dateRange) => {
      const dateRangeValue = _timeDurationOptions.find(
        (duration) => duration.label === dateRange,
      );
      setInterval(dateRangeValue.value);
    },
    [setInterval],
  );
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <TimePicker
        defaultVal={dateSelectorOptions.label}
        onChangeDateRange={(newDateRange) => handleDateSelect(newDateRange)}
        timeDurationOptions={_timeDurationOptions}
        sx={{
          backgroundColor: "#003F2D14",
          color: "#003F2D",
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "16px",
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          height: "30px",
          minWidth: "8rem",
        }}
      />
    </Stack>
  );
};

ToolbarActions.propTypes = {
  setInterval: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const TopRecurringIssuesWidget = () => {
  const [interval, setInterval] = useState(dateSelectorOptions.value);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowPerpage] = useState(10);
  const [selected, setSelected] = useState([]);
  const { refreshDuration, refreshEvent } = useDashboardContext();
  const navigationCtx = useNavigationCtx();
  const projectId = navigationCtx?.state.selectedOption?.project_nexus_id;
  const buildingId = navigationCtx?.state.selectedOption?.nexusId;
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("issue_occurrences");
  const issuesWidgetType = "recurringIssues";

  const columns = [
    { id: "issue_name", label: "Issue Name" },
    { id: "issue_occurrences", label: "Issue Occurrences" },
    { id: "events_count", label: "Event Count" },
    { id: "equip_name", label: "Affected Asset", type: "link" },
    {
      id: "total_issue_cost",
      label: "Est. Cost Impact (USD)",
      numeric: true,
      align: "right",
    },
  ];

  const {
    data: recurringIssuesData,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["recurringIssues", projectId, buildingId, refreshEvent],
    enabled: !!projectId && !!buildingId,
    queryFn: () =>
      getTop10RecurringIssues(
        projectId,
        buildingId,
        issuesWidgetType,
        interval,
      ),
    refetchInterval: 1000 * parseInt(refreshDuration) * 60,
  });

  const handleChangePage = (event, newPage) => {
    event.stopPropagation();
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    event.stopPropagation();
    setRowPerpage(event.target.value);
  };
  const descendingComparator = (a, b, orderBy) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    // Fast path for null/undefined
    if (!bValue) return -1;
    if (!aValue) return 1;

    // Fast path for non-objects
    if (typeof aValue !== "object" || typeof bValue !== "object") {
      return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
    }

    // For objects, use JSON.stringify for fast comparison if objects are identical
    if (JSON.stringify(aValue) === JSON.stringify(bValue)) return 0;

    // Quick numeric comparison for object values
    let comparison = 0;
    const aKeys = Object.keys(aValue);

    // Only iterate through keys that exist in first object for speed
    for (let i = 0; i < aKeys.length && comparison === 0; i++) {
      const key = aKeys[i];
      comparison = (bValue[key] || 0) - (aValue[key] || 0);
    }

    return comparison;
  };

  const getComparator = useCallback((order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }, []);

  useEffect(() => {
    if (refetch) {
      refetch();
      setPage(0);
    }
  }, [interval, refetch]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const rows = useMemo(() => {
    const rowArray = [];
    if (recurringIssuesData?.status === "success") {
      const recurringIssues = recurringIssuesData?.data?.values;
      const sortedRecurringIssuesData = recurringIssues.sort(
        getComparator(order, orderBy),
      );
      sortedRecurringIssuesData?.forEach((issue, index) => {
        rowArray.push({
          issue_name: issue.issue_name,
          id: index,
          issue_occurrences: issue.issue_occurrences,
          events_count: issue.events_count,
          equip_name: issue.equip_name,
          equip_name: (
            <NavLink
              name={issue.equip_name}
              id={issue?.equip_ref}
              interval={interval}
            />
          ),
          total_issue_cost: formatNumber(issue.total_issue_cost, {
            style: "currency",
            currency: "USD",
          }),
        });
      });
    }
    return rowArray;
  }, [recurringIssuesData, getComparator, order, orderBy, interval]);

  const WidgetErrorReload = ({ onReload }) => (
    <div className="top" style={{ height: "100%" }}>
      <div className="top">{/* <WidgetCardTitle /> */}</div>
      <ErrorCardContent onReload={onReload} />
    </div>
  );

  WidgetErrorReload.propTypes = {
    onReload: PropTypes.func,
  };

  const visibleRows = useMemo(
    () => [...rows].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [rows, page, rowsPerPage],
  );

  if (isError) return <WidgetErrorReload onReload={refetch} />;

  return (
    <WidgetCard
      top={
        <EnhancedTableToolbar
          tableName={"Top 10 Recurring Issues"}
          sx={{
            "&.MuiToolbar-root": { padding: "16px", minHeight: "30px" },
            backgroundColor: "#fff",
            // border: "1px solid #E0E0E0",
            borderBottom: 0,
            // borderRadius: "4px",
          }}
        >
          {<ToolbarActions setInterval={setInterval} disabled={!rows.length} />}
        </EnhancedTableToolbar>
      }
      scrollContainer={
        <EnhancedTable
          isLoading={isLoading}
          columns={columns}
          order={order}
          orderBy={orderBy}
          pagination={{
            page,
            pageHandler: handleChangePage,
            rowsPerPage,
            rowsPerPageHandler: handleChangeRowsPerPage,
            totalRows: rows.length,
          }}
          requestSort={handleRequestSort}
          rows={visibleRows}
          selectAllClick={handleSelectAllClick}
          selected={selected}
          issuesWidgetType={issuesWidgetType}
        />
      }
    />
  );
};

export default TopRecurringIssuesWidget;
