import { truncateWithEllipsis } from '@smartfm/container/utils/strings';
import { Button } from '@smartfm/ui/components/Button';
import { IconButton } from '@smartfm/ui/components/IconButton';
import {
  SearchMenu,
  type SearchMenuProps,
} from '@smartfm/ui/components/SearchMenu';
import pluralize from 'pluralize';
import './styles.scss';

const stickyListItem = {
  value: '__ALL_BUILDINGS_VALUE__' as const,
  label: 'All Buildings',
} satisfies SearchMenuProps['itemsMap']['string'];

export interface BuildingSelectorProps
  extends Omit<
    SearchMenuProps,
    | 'triggerElement'
    | 'trailingHeaderElement'
    | 'stickyListItemProps'
    | 'width'
    | 'itemHeight'
  > {
  defaultLabel: string;
  totalCount: number;
}

export function BuildingSelector(props: BuildingSelectorProps) {
  const {
    value,
    itemsMap,
    totalCount,
    defaultLabel,
    onValueChange,
    favoriteItems,
    ...otherProps
  } = props;

  const { label } = (value === stickyListItem.value
    ? stickyListItem
    : itemsMap[value]) || { label: defaultLabel };

  function handleAllBuildingsClick() {
    onValueChange(stickyListItem.value);
  }

  return (
    <SearchMenu
      // Due to the way the EmeraldMenu component is implemented,
      // The key is required to force the component to re-render
      // To close the menu each time the value changes
      key={value}
      width={448}
      itemHeight={65}
      triggerElement={
        <Button
          icon={favoriteItems.includes(value) ? 'star' : 'place'}
          rightIcon='arrow_drop_down'
          size='Small'
          type='TextButton'
          className='building-selector__trigger'
          title={label}
          label={truncateWithEllipsis(label, 30)}
        />
      }
      trailingHeaderElement={
        <IconButton
          disabled={true}
          icon='view_list'
          iconFillType='rounded'
          size='Large'
          type='Light'
          title='Advanced building selection'
        />
      }
      stickyListItemProps={{
        secondaryText: pluralize('Building', totalCount, true),
        onClick: handleAllBuildingsClick,
        ...stickyListItem,
      }}
      value={value}
      itemsMap={itemsMap}
      onValueChange={onValueChange}
      favoriteItems={favoriteItems}
      {...otherProps}
    />
  );
}

BuildingSelector.__ALL_BUILDINGS_VALUE__ = stickyListItem.value;
