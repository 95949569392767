import { createContext } from 'react';

interface TDashboardContext {
  refreshDuration: number;
  setRefreshDuration: (duration: number) => void;
  refreshEvent: boolean;
  triggerRefresh: () => void;
}

export const DashboardContext = createContext<TDashboardContext>({
  refreshDuration: 5,
  setRefreshDuration: () => {},
  refreshEvent: false,
  triggerRefresh: () => {},
});
