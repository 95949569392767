/**
 *
 * @param {{
 *  data:  {
 *      name: string;
 *      value: number;
 *      color: string;
 *      }[]
 * }} param0
 * @returns
 */
export const PercentageBar = ({ data }) => {
  const dataTotal = data.reduce((n, { value }) => n + value, 0);
  return (
    <div className='percentage-bar'>
      <div className='bar-bg'>
        {data.map((entry, index) => (
          <div
            key={index}
            className='metric'
            style={{
              backgroundColor: entry.color,
              width: `${(entry.value / dataTotal) * 100}%`,
            }}
          ></div>
        ))}
      </div>
    </div>
  );
};
