import staticConfig from './config.json' with { type: 'json' };
import { getEnv } from '#utils/env.ts';
import merge from 'lodash.merge';

const env = getEnv<Exclude<keyof typeof staticConfig, 'default'>>();
const defaultConfig = staticConfig.default;
const envConfig = staticConfig[env];

const config = merge(
  {},
  // Default config
  defaultConfig,
  // Environment specific config
  envConfig
);

export { config };
