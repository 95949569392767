import { truncateWithEllipsis } from '@smartfm/container/utils/strings';
import { Button } from '@smartfm/ui/components/Button';
import {
  SearchMenu,
  type SearchMenuProps,
} from '@smartfm/ui/components/SearchMenu';
import './styles.scss';

export interface ClientSelectorProps
  extends Omit<
    SearchMenuProps,
    | 'triggerElement'
    | 'trailingHeaderElement'
    | 'stickyListItemProps'
    | 'width'
    | 'itemHeight'
    | 'itemsMap'
  > {
  itemsMap: Record<
    keyof SearchMenuProps['itemsMap'],
    Omit<SearchMenuProps['itemsMap']['string'], 'secondaryText'>
  >;
  defaultLabel: string;
}

export function ClientSelector(props: ClientSelectorProps) {
  const {
    value,
    itemsMap,
    defaultLabel,
    onValueChange,
    favoriteItems,
    ...otherProps
  } = props;
  const { label } = itemsMap[value] || { label: defaultLabel };

  return (
    <SearchMenu
      // Due to the way the EmeraldMenu component is implemented,
      // The key is required to force the component to re-render
      // To close the menu each time the value changes
      key={value}
      width={322}
      itemHeight={50}
      triggerElement={
        <Button
          icon={favoriteItems.includes(value) ? 'star' : undefined}
          rightIcon='arrow_drop_down'
          size='Small'
          type='Light'
          className='client-selector__trigger'
          title={label}
          label={truncateWithEllipsis(label, 30)}
        />
      }
      value={value}
      itemsMap={itemsMap}
      favoriteItems={favoriteItems}
      onValueChange={onValueChange}
      {...otherProps}
    />
  );
}
