import { NavigationProvider } from '@/components/NavigationProvider';
import { SidebarProvider } from '@/context/sidebar';
import { QueryProvider, queryClient } from '@/context/query';
import { ThemeProvider } from '@mui/material/styles';
import type { PropsWithChildren } from 'react';
import { lightTheme } from '@/theme';
import { DrawerProvider } from '@/components/layouts/DrawerProvider';
import { SnackbarProvider } from '@/components/layouts/SnackbarContext';

export function Providers(props: PropsWithChildren) {
  const { children } = props;

  return (
    <QueryProvider queryClient={queryClient}>
      <NavigationProvider>
        <SidebarProvider>
          <ThemeProvider theme={lightTheme}>
            <SnackbarProvider>
              <DrawerProvider>{children}</DrawerProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </SidebarProvider>
      </NavigationProvider>
    </QueryProvider>
  );
}
