import { useClients } from '@smartfm/container/hooks/useClients';
import { Button } from '@smartfm/ui/components/Button';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAppLinkForClient } from '@/utils/functions';
import {
  getAvailableAppsForClient,
  useProjects,
} from '@/libs/container/hooks/useProjects';
import { type ClientSelectorProps, ClientSelector } from './ClientSelector';

const storagePrefixKey = 'clientSelector';
const isFavoriteFilterActiveKey = [
  storagePrefixKey,
  'isFavoriteFilterActive',
].join('.');
const favoriteItemsKey = [storagePrefixKey, 'favoriteClients'].join('.');

interface ConnectedClientSelectorProps {
  position: NonNullable<ClientSelectorProps['position']>;
  clientId: string;
}

export function ConnectedClientSelector(props: ConnectedClientSelectorProps) {
  const { clientId, ...otherProps } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [searchField, setSearchField] = useState<string>();
  const { itemsMap, itemsOrder, isPending, error, refetch } = useClients({
    searchField,
  });
  const useProjectsQuery = useProjects();

  // Keep the current label in case
  // the new search results don't contain the current value
  const defaultLabelRef = useRef('');

  // Keep the current label in case
  // the new search results don't contain the current value
  useEffect(() => {
    const item = itemsMap[clientId];

    // If the item is not found, do nothing
    if (!item) return;
    defaultLabelRef.current = item.label;
  }, [itemsMap, clientId]);

  const [isFavoriteFilterActive, setIsFavoriteFilterActive] = useLocalStorage(
    isFavoriteFilterActiveKey,
    false
  );

  const [favoriteItems, setFavoriteItems] = useLocalStorage<string[]>(
    favoriteItemsKey,
    []
  );

  function handleFavoriteChange(value: string) {
    setFavoriteItems(existingFavoriteItems => {
      const updatedFavorites = existingFavoriteItems.includes(value)
        ? existingFavoriteItems.filter(item => item !== value)
        : [...existingFavoriteItems, value];

      if (updatedFavorites.length === 0) {
        setIsFavoriteFilterActive(false);
      }

      return updatedFavorites;
    });
  }

  function handleValueChange(clientId: string) {
    let hasAccessToCurrentApp = true;
    if (useProjectsQuery.data) {
      const availableApps = getAvailableAppsForClient(
        useProjectsQuery.data,
        clientId
      );
      const currentNavPath = location.pathname.slice(1); // Remove '/' from the beginning

      hasAccessToCurrentApp =
        currentNavPath === '' ||
        availableApps.some(app => app.nav_path === currentNavPath);
    }

    if (hasAccessToCurrentApp) {
      const newPath = getAppLinkForClient(location.pathname, clientId);
      navigate(newPath);
    } else {
      // Navigate to dashboard
      const queryParams = new URLSearchParams({
        recordId: clientId,
      });

      navigate(`/?${queryParams.toString()}`);
    }
  }

  // Reset the search field when the selector is closed
  function handleClose() {
    setSearchField('');
  }

  function handleRefetch() {
    refetch();
  }

  // TODO: Consider using isLoading prop inside the ClientSelector component
  // or <ClientSelector.Loading /> component
  if (isPending) {
    return (
      <Button
        icon='home'
        rightIcon='arrow_drop_down'
        size='Small'
        type='Light'
        className='client-selector__trigger'
        label='Fetching ...'
      />
    );
  }

  if (error) {
    return (
      <Button
        icon='refresh'
        size='Small'
        type='Light'
        className='client-selector__trigger'
        label='Refetch Clients'
        onClick={handleRefetch}
      />
    );
  }

  return (
    <ClientSelector
      // Value handling
      value={clientId}
      itemsMap={itemsMap}
      itemsOrder={itemsOrder}
      onValueChange={handleValueChange}
      defaultLabel={defaultLabelRef.current}
      // Search handling
      searchValue={searchField}
      onSearchChange={setSearchField}
      onClose={handleClose}
      // Favorite items handling
      favoriteItems={favoriteItems}
      onFavoriteChange={handleFavoriteChange}
      isFavoriteFilterActive={isFavoriteFilterActive}
      onFavoriteFilterChange={setIsFavoriteFilterActive}
      {...otherProps}
    />
  );
}
