import { EmeraldIconButton } from '@emerald-react/icon-button';
import type { EmeraldIconTypes } from '@emerald/nxcore';
import type { ComponentProps } from 'react';

export interface IconButtonProps
  extends Omit<ComponentProps<typeof EmeraldIconButton>, 'icon'> {
  icon: EmeraldIconTypes;
}

export function IconButton(props: IconButtonProps) {
  return <EmeraldIconButton {...props} />;
}
