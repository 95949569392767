import { Skeleton, Stack, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import useStyles from './useStyles';
import {
  ArrowDownward,
  ArrowUpward,
  HorizontalRule,
  Warning,
} from '@mui/icons-material';
import { formatNumber } from '@/utils/functions';

const makeCurrency = value =>
  formatNumber(Math.abs(value), {
    style: 'currency',
    currency: 'USD',
  });

function AssetTrendCell({ deviation, isLoading = false }) {
  const classes = useStyles();
  const theme = useTheme();
  const textColors =
    deviation < 0
      ? theme.palette.success.main
      : deviation > 0
        ? theme.palette.error.main
        : theme.palette.text.disabled;
  const iconClasses = { fontSize: 14, fill: textColors };
  const upArrow = <ArrowUpward sx={iconClasses} />;
  const downArrow = <ArrowDownward sx={iconClasses} />;
  const noChange = <HorizontalRule sx={iconClasses} />;

  const healthElement = {};
  if (deviation > 0) {
    healthElement.icon = upArrow;
  } else if (deviation === 0) {
    healthElement.icon = noChange;
  } else if (deviation < 0) {
    healthElement.icon = downArrow;
  } else {
    healthElement.icon = <Warning fontSize='8' />;
  }
  healthElement.text = (
    <Typography style={{ color: textColors, fontSize: '14px' }}>
      {makeCurrency(deviation) || 'N/A'}
    </Typography>
  );
  return (
    <Stack
      direction='row'
      spacing={1}
      className={classes.healthScoreCell}
    >
      {isLoading ? (
        <>
          <Skeleton
            variant='circular'
            width={20}
            height={20}
          />
          <Skeleton
            variant='text'
            width={40}
            height={20}
          />
        </>
      ) : (
        <>
          {healthElement.icon}

          {healthElement.text}
        </>
      )}
    </Stack>
  );
}
AssetTrendCell.propTypes = {
  deviation: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
};
export default AssetTrendCell;
