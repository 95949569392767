import { config } from '@/config';
import { AnalyticsBrowser } from '@segment/analytics-next';

export async function identifyUserForAnalytics(id: string) {
  const { segmentIoKey } = config;
  const analytics = AnalyticsBrowser.load({ writeKey: segmentIoKey });

  // Pass UUID to Segment via identify method
  await analytics.identify(id);
}
