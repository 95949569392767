import { useStyles } from './useStyles';

import {
  Card,
  CardContent,
  Typography,
  Box,
  Stack,
  Skeleton,
} from '@mui/material';
import './RecommendedActions.scss';

const RecommendedActions = ({
  date,
  title,
  assetName,
  alarmCounts,
  issueCount = 0,
  issueType = null,
  recommendedAction,
  costImpact = null,
  issueName,
  issueOccurrenceCount = 0,
  isLoading,
}) => {
  const classes = useStyles();
  const formatDollarAmount = amount => {
    if (typeof amount !== 'number') return amount;
    return amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  };
  return isLoading ? (
    <Stack spacing={1}>
      <Box className={classes.widgetContainer}>
        <Card className={`${classes.widgetCard}`}>
          <CardContent className={classes.widgetCardContent}>
            <Skeleton
              variant='rectangular'
              width={320}
              height={322}
              data-testid='loader'
            />
          </CardContent>
        </Card>
      </Box>
    </Stack>
  ) : (
    <Stack
      direction='row'
      spacing={2}
    >
      <Box className={classes.widgetContainer}>
        <Card className={classes.widgetCard}>
          <CardContent
            className={classes.widgetCardContent}
            data-testid='card'
          >
            <Box className={classes.widgetHead}>
              <Box className={classes.widgetHeadContainer}>
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                >
                  <Typography className={classes.widgetHeading1}>
                    Insight
                    <Box
                      element='span'
                      className={classes.insightUnderline}
                    ></Box>
                  </Typography>
                  <Typography className={classes.widgetDate}>{date}</Typography>
                </Box>

                <Typography className={classes.widgetHeading2}>
                  {title}
                </Typography>
                <Typography className={classes.widgetHeading3}>
                  Condition Based Maintenance
                </Typography>
              </Box>
            </Box>
            <Box className={classes.widgetBody}>
              {issueOccurrenceCount && issueName ? (
                <Box>
                  <Typography className={classes.widgetBodyText}>
                    <Box
                      component={"span"}
                      className={classes.widgetBodyTextBold}
                    >
                      {issueName}
                    </Box>
                    {` issue has happened ${issueOccurrenceCount} times in last 3 months. Its est. cost impact is `}
                    <Box
                      component="span"
                      className={classes.widgetBodyTextBoldRed}
                    >
                      {`${formatDollarAmount(costImpact)}.`}
                    </Box>
                  </Typography>
                </Box>
              ) : costImpact ? (
                <Box>
                  <Typography className={classes.widgetBodyText}>
                    {'During the past 30 days in '}
                    <Box
                      component={'span'}
                      className={classes.widgetBodyTextBold}
                    >
                      {assetName}
                    </Box>
                    {`, the estimated cost was `}
                    <Box
                      component='span'
                      className={classes.widgetBodyTextBoldRed}
                    >
                      {`${formatDollarAmount(costImpact)}.`}
                    </Box>
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <Typography className={classes.widgetBodyText}>
                    <Box
                      component={'span'}
                      className={classes.widgetBodyTextBold}
                    >
                      {assetName}
                    </Box>
                    {` is in poor health with ${alarmCounts} events for `}
                    <Box
                      component='span'
                      className={classes.widgetBodyTextBoldRed}
                    >
                      {`${issueCount} ${issueType}.`}
                    </Box>
                  </Typography>
                </Box>
              )}

              <Box className={classes.recommendationContainer}>
                <Typography
                  className='recommended-actions'
                  variant='body2'
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  <Box
                    component={'span'}
                    className={classes.recommendationHeadingText}
                  >
                    {'Recommended Action: '}
                  </Box>
                  {recommendedAction}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Stack>
  );
};

export default RecommendedActions;
