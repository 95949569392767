import type { EmeraldInputInputs } from '@emerald-react/input';
import { EmeraldInput } from '@emerald-react/input';
import type { EmeraldIconTypes } from '@emerald/nxcore';

export interface InputProps extends Omit<EmeraldInputInputs, 'trailingIcon'> {
  trailingIcon?: EmeraldIconTypes;
}

export function Input(props: InputProps) {
  return <EmeraldInput {...props} />;
}
