import { useNavigationCtx } from '@/components/NavigationProvider';
import { Stack } from '@smartfm/ui/components/Stack';
import { ConnectedBuildingSelector } from './BuildingSelector';
import { ConnectedClientSelector } from './ClientSelector';
import { Box } from '@smartfm/ui/components/Box';

export function ClientBuildingSelector() {
  // TODO: Update this logic in Smart FM 2.0
  // to use a new approach
  const navigationCtx = useNavigationCtx();

  if (!navigationCtx) return null;
  const { state } = navigationCtx;
  const { selectedOption } = state;
  if (!selectedOption) return null;
  const { project_nexus_id, nexusId } = selectedOption;

  // If "project_nexus_id" is available,
  // use it as the "clientId" for the selected building.
  // If "project_nexus_id" is not available,
  // assume it's the All Buildings View and use "nexusId" as the "clientId".
  const clientId = project_nexus_id || nexusId;
  if (!clientId) return null;

  return (
    <Stack
      direction='row'
      alignItems='center'
    >
      <ConnectedBuildingSelector
        position='bottom_end'
        clientId={clientId}
      />
      <Box
        sx={{
          height: '100%',
          opacity: 0.6,
          paddingLeft: 1.5,
          paddingRight: 3,
        }}
      >
        {' | '}
      </Box>
      <ConnectedClientSelector
        position='bottom_end'
        clientId={clientId}
      />
    </Stack>
  );
}
