import { Typography, useTheme } from '@mui/material';
import { truncateWithEllipsis } from '@smartfm/container/utils/strings';
import { clsx } from 'clsx';
import type { ReactNode } from 'react';
import { FixedSizeList as VirtualizedList } from 'react-window';
import { Box, type BoxProps } from '../Box';
import { Divider } from '../Divider';
import { Icon } from '../Icon';
import { IconButton } from '../IconButton';
import { Input, type InputProps } from '../Input';
import { ListItem, type ListItemProps } from '../ListItem';
import { Menu, type MenuProps } from '../Menu';
import { ProgressBar } from '../ProgressBar';
import { Stack } from '../Stack';
import './styles.scss';

const virtualizedListItemsThreshold = 25;
const itemsListMaxHeight = 314;
const searchInputMaxLength = 100;

function ProgressBarElement() {
  return (
    <Box sx={{ position: 'absolute', width: '100%' }}>
      <ProgressBar determinate={false} />
    </Box>
  );
}

function CheckIconElement() {
  const theme = useTheme();
  return (
    /* 
      TODO: Box with color="primary.main" is a temporary workaround to fix the color of the icon.
      Should be revised after Emerald System is updated.
    */
    <Box
      sx={{
        color: 'primary.main',
        gridArea: '1/1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 52,
      }}
    >
      <Icon
        icon='check'
        type='rounded'
        color={theme.palette.primary.light}
      />
    </Box>
  );
}

function BodyContainer(props: BoxProps) {
  const { sx, ...otherProps } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
      {...otherProps}
    />
  );
}

interface BodyProps {
  width: number;
  itemHeight: number;
  value: string;
  itemsMap: Record<string, Omit<ListItemProps, 'label'> & { label: string }>;
  itemsOrder: string[];
  favoriteItems: string[];
  onValueChange(value: string): void;
  onFavoriteChange(value: string): void;
  stickyListItem?: ReactNode;
  isLoading?: boolean;
}

function Body(props: BodyProps) {
  const {
    width,
    itemHeight,
    value,
    itemsMap,
    itemsOrder,
    favoriteItems,
    onValueChange,
    onFavoriteChange,
    stickyListItem,
    isLoading,
  } = props;

  const containerHeight =
    itemsListMaxHeight + (stickyListItem ? 0 : itemHeight);

  // Show a message when there are no search results
  if (itemsOrder.length === 0) {
    return (
      <>
        {stickyListItem}
        {isLoading && <ProgressBarElement />}
        <BodyContainer sx={{ height: containerHeight }}>
          <Typography
            color='textSecondary'
            textAlign='center'
          >
            No results found. <br />
            Please try another search.
          </Typography>
        </BodyContainer>
      </>
    );
  }

  function handleItemClick(
    ...[, item]: Parameters<NonNullable<ListItemProps['onClick']>>
  ) {
    onValueChange(item.value);
  }

  function renderListItem(item: ListItemProps) {
    const isFavorite = favoriteItems.includes(item.value);
    const isActive = item.value === value;
    const { label, secondaryText, ...otherProps } = item;

    // Limit the label for one line when there is secondary text
    const maxLabelLength = (secondaryText ? 40 : 80) / (width > 400 ? 1 : 2);
    const formattedLabel = label && truncateWithEllipsis(label, maxLabelLength);

    return (
      <Box
        title={label}
        sx={{
          flexGrow: 1,
          display: 'grid',
          height: itemHeight,
        }}
      >
        {isActive && <CheckIconElement />}
        <ListItem
          classes='search-menu__list-item'
          onClick={handleItemClick}
          trailingTemplate={
            /* 
              TODO: Box with color="primary.main" is a temporary workaround to fix the color of the icon.
              Should be revised after Emerald System is updated.
            */
            <Box color='primary.main'>
              <IconButton
                type='Icon'
                size='Large'
                shape='Circle'
                icon='star'
                title={
                  isFavorite ? 'Remove from favorites' : 'Add to favorites'
                }
                iconFillType='rounded'
                className={clsx('search-menu__favorite-button', {
                  'search-menu__favorite-button--active': isFavorite,
                })}
                onClick={event => {
                  // Prevent the click event from bubbling up to the ListItem
                  event.stopPropagation();
                  onFavoriteChange(item.value);
                }}
              />
            </Box>
          }
          label={formattedLabel}
          secondaryText={
            secondaryText && truncateWithEllipsis(secondaryText, 100)
          }
          {...otherProps}
        />
      </Box>
    );
  }

  // Render a regular list if the number of items is below the threshold
  if (itemsOrder.length < virtualizedListItemsThreshold) {
    return (
      <>
        {stickyListItem}
        {isLoading && <ProgressBarElement />}
        <Box sx={{ maxHeight: containerHeight, overflow: 'auto' }}>
          {itemsOrder.map(value => {
            const item = itemsMap[value];
            return (
              <Box
                key={item.value}
                sx={{ height: itemHeight, display: 'flex' }}
              >
                {renderListItem(item)}
              </Box>
            );
          })}
        </Box>
      </>
    );
  }

  // Render a virtualized list if the number of items is above the threshold
  return (
    <>
      {stickyListItem}
      {isLoading && <ProgressBarElement />}
      <VirtualizedList
        height={containerHeight}
        width={width}
        itemCount={itemsOrder.length}
        itemSize={itemHeight}
      >
        {({ index, style }) => {
          const value = itemsOrder[index];
          const item = itemsMap[value];
          return (
            <Box
              key={item.value}
              sx={{ ...style, display: 'flex' }}
            >
              {renderListItem(item)}
            </Box>
          );
        }}
      </VirtualizedList>
    </>
  );
}
export interface SearchMenuProps
  extends Omit<BodyProps, 'stickyListItem' | 'onFavoriteChange'>,
    Pick<MenuProps, 'opened' | 'triggerElement' | 'position' | 'onClose'> {
  value: string;
  triggerElement: NonNullable<MenuProps['triggerElement']>;
  stickyListItemProps?: Omit<ListItemProps, 'classes'>;
  searchValue: InputProps['value'];
  onSearchChange(searchValue: string): void;
  onFavoriteChange(value: string): void;
  onFavoriteFilterChange(value: boolean): void;
  isFavoriteFilterActive: boolean;
  favoriteItems: string[];
  trailingHeaderElement?: ReactNode;
}

export function SearchMenu(props: SearchMenuProps) {
  const {
    width,
    itemHeight,
    opened,
    onClose,
    position,
    triggerElement,
    value,
    itemsMap,
    itemsOrder,
    searchValue,
    onValueChange,
    onSearchChange,
    onFavoriteChange,
    onFavoriteFilterChange,
    stickyListItemProps,
    trailingHeaderElement,
    isFavoriteFilterActive,
    favoriteItems,
    isLoading,
  } = props;

  const hasFavorites = favoriteItems.length > 0;
  const currentItemsOrder = isFavoriteFilterActive
    ? itemsOrder.filter(value => favoriteItems.includes(value))
    : itemsOrder;

  function handleFilterFavoritesClick() {
    onFavoriteFilterChange(!isFavoriteFilterActive);
  }

  return (
    <Menu
      triggerElement={triggerElement}
      position={position}
      // This prop is exposed for Storybook testing only
      opened={opened}
      onClose={onClose}
    >
      <Box
        sx={{
          maxHeight: 448,
          overflow: 'hidden',
          width,
        }}
      >
        <Stack
          direction='row'
          spacing={1.25}
          useFlexGap={true}
          sx={{ padding: theme => theme.spacing(1, 1.5, 1.5) }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Input
              placeholder='Search'
              trailingIcon='search'
              showClearIcon={true}
              maxLength={searchInputMaxLength}
              value={searchValue}
              onChange={(_, changedValue) => onSearchChange(changedValue)}
            />
          </Box>
          {/* 
            TODO: Box with color="primary.main" is a temporary workaround to fix the color of the icon.
            Should be revised after Emerald System is updated.
          */}
          <Box color='primary.main'>
            <IconButton
              icon='star'
              iconFillType='rounded'
              size='Large'
              type='Light'
              disabled={!hasFavorites}
              title={
                !hasFavorites
                  ? 'No favorites'
                  : isFavoriteFilterActive
                    ? 'Show all'
                    : 'Show favorites'
              }
              onClick={handleFilterFavoritesClick}
              className={clsx('search-menu__favorite-button', {
                'search-menu__favorite-button--active': isFavoriteFilterActive,
              })}
            />
          </Box>
          {trailingHeaderElement && (
            <Box color='primary.main'>{trailingHeaderElement}</Box>
          )}
        </Stack>
        <Body
          width={width}
          itemHeight={itemHeight}
          value={value}
          itemsMap={itemsMap}
          itemsOrder={currentItemsOrder}
          favoriteItems={favoriteItems}
          stickyListItem={
            stickyListItemProps && (
              <>
                <Box
                  sx={{
                    display: 'grid',
                    height: itemHeight,
                  }}
                >
                  {value === stickyListItemProps.value && <CheckIconElement />}
                  <ListItem
                    classes='search-menu__list-item'
                    {...stickyListItemProps}
                  />
                </Box>
                <Divider />
              </>
            )
          }
          isLoading={isLoading}
          onValueChange={onValueChange}
          onFavoriteChange={onFavoriteChange}
        />
      </Box>
    </Menu>
  );
}
