import { useLocation } from 'react-router-dom';

// TODO: Define route constants as a single source of truth for the entire application
const appMappings = [
  ['admin', 'Administration'],
  ['cbm', 'Condition Based Maintenance'],
  ['vantage-dashboard', 'Vantage Reports'],
  ['reflow', 'Building Details'],
  ['smart-cleaning', 'Smart Cleaning'],
  ['issue', 'Issues'],
  ['pipe-monitoring', 'Pipe Monitoring'],
  ['maintenance', 'Maintenance'],
  ['moe', 'Maintenance Optimization Engine'],
];

function getActiveAppName(pathname: string) {
  for (const [fragment, appName] of appMappings) {
    if (pathname.startsWith(`/ui/${fragment}`)) {
      return appName;
    }
  }
  return null;
}

export function useActiveAppName() {
  const { pathname } = useLocation();
  return getActiveAppName(pathname);
}
