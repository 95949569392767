import { api as bffHttpClient } from '@/utils/Api';
import { to } from '@/utils/to';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';

interface Building {
  nexus_id: string;
  nav_name: string;
  address: string | null;
  enabled: boolean;
}

interface Response {
  totalCount: number;
  data: Building[];
}

interface Params {
  clientId: string;
  searchField?: string;
}

async function getClientBuildings(params: Params) {
  const { clientId, searchField } = params;

  const [data, error] = await to(
    bffHttpClient.get<Response>('/navigation/buildings', {
      params: {
        projectId: clientId,
        ...(searchField ? { searchField } : {}),
        isEnabled: true,
      }
    })
  );

  if (error) {
    return null;
  }

  return data;
}

const queryKey = 'clientBuildings';

interface UseClientBuildingsProps<TResponse>
  extends Params,
    Omit<UseQueryOptions, 'queryKey' | 'queryFn' | 'staleTime'> {
  transform?(response: AxiosResponse<Response>): TResponse;
}

function useClientBuildings<TResponse>(
  props: UseClientBuildingsProps<TResponse>
) {
  const { clientId, searchField, transform } = props;

  const queryResult = useQuery({
    queryKey: [queryKey, clientId, searchField],
    queryFn: () =>
      getClientBuildings({ clientId, searchField }).then(response => {
        if (!response || !transform) return response;
        return transform(response);
      }),
    placeholderData: previousData => previousData,
  });

  return queryResult;
}

export { queryKey, useClientBuildings };
